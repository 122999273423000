import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography
} from "@mui/material";
import ArrowIcon from "@mui/icons-material/ArrowRightRounded";
import { Wrapper } from "./styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import useAxios from "../../../../../components/UseAxios/useAxios";
import { useCallback, useEffect, useMemo, useState } from "react";
import TaskService from "../../../../../services/api/task.service";
import { UniqueIdentifier } from "@dnd-kit/core";
import { IStageHistory, ITaskCard } from "../../../../../models/Task";
import { toggleMessage } from "../../../../../components/Toast/Toast";
import dayjs from "dayjs";
import CreateTaskModal from "../../../CreateTaskModal/create_task_modal";
import { ETaskStatus } from "../../../../../models/common/models.enum";

interface Props {
  TaskCardId: UniqueIdentifier;
  BoardId: UniqueIdentifier;
  stages: IStageHistory[];
  isHasPermission?: boolean;
  force: string;
}

interface TaskByName {
  name: string;
  tasks: ITaskCard[];
}

const ChildrenTask = ({
  BoardId,
  TaskCardId,
  stages,
  isHasPermission,
  force
}: Props) => {
  const [tasks, setTasks] = useState<ITaskCard[]>([]);
  const [groupTask, setGroupTask] = useState<TaskByName[]>([]);
  const [taskUpdate, setTaskUpdate] = useState<ITaskCard | undefined>(
    undefined
  );
  const getTasksAxios = useAxios<ITaskCard[]>({ loading: "OnRequest" });
  const updateStatusAxios = useAxios<string[]>({ loading: "OnRequest" });
  const _ = require("lodash");

  const groupStages = useMemo(() => {
    return _.groupBy(stages, (stage: IStageHistory) => stage.id);
  }, [stages]);

  useEffect(() => {
    fetchChildrenTasks();
  }, [force]);

  const fetchChildrenTasks = useCallback(() => {
    getTasksAxios.request(
      TaskService.getPageChildren({
        CurrentPage: 1,
        PageSize: 30,
        BoardId: BoardId,
        TaskCardId: TaskCardId
      })
    );
  }, []);

  const handleUpdateStatus = useCallback(
    (id: UniqueIdentifier, status: ETaskStatus | null) => {
      if (updateStatusAxios.isLoading) return;

      updateStatusAxios.request(
        TaskService.updateStatus(
          id,
          status !== ETaskStatus.Complete
            ? ETaskStatus.Complete
            : ETaskStatus.Inprogress
        )
      );
    },
    []
  );

  useEffect(() => {
    var TasksByName: any = [];
    if (groupStages) {
      tasks.reduce((group, task) => {
        const stageId = task.workflowId as string;
        const stageName = groupStages[stageId].at(0)?.name;
        if (stageName) {
          if (!TasksByName[stageName]) {
            TasksByName[stageName] = [];
          }
          TasksByName[stageName].push(task);
        }
        return group;
      }, []);
    }
    setGroupTask(
      Object.entries<ITaskCard[]>(TasksByName).map<TaskByName>(e => ({
        name: e[0],
        tasks: e[1]
      }))
    );
  }, [groupStages, tasks]);

  useEffect(() => {
    if (getTasksAxios.isSuccess && getTasksAxios.data) {
      setTasks(getTasksAxios.data);
    }
    if (getTasksAxios.error) {
      toggleMessage({
        type: "error",
        message: getTasksAxios.error.message ?? ""
      });
    }
  }, [getTasksAxios.isLoading, getTasksAxios.isSuccess, getTasksAxios.error]);

  useEffect(() => {
    if (updateStatusAxios.isSuccess && updateStatusAxios.data) {
      fetchChildrenTasks();
    }
    if (updateStatusAxios.error) {
      toggleMessage({
        type: "error",
        message: updateStatusAxios.error.message ?? ""
      });
    }
  }, [
    updateStatusAxios.isSuccess,
    updateStatusAxios.data,
    updateStatusAxios.error
  ]);

  return (
    <Wrapper>
      {tasks.length === 0 && (
        <Typography
          fontSize={14}
          color="GrayText"
          fontWeight="400"
          marginTop="3px"
        >
          {"Chưa có công việc!"}
        </Typography>
      )}
      {tasks.length > 0 &&
        groupTask.map((group: TaskByName, i: number) => (
          <Accordion
            key={group.name + i}
            sx={{
              "::before": {
                height: 0
              }
            }}
          >
            <AccordionSummary
              aria-controls="panel1-content"
              sx={{
                padding: "0px 8px"
              }}
            >
              <ArrowIcon sx={{ color: "GrayText", fontSize: 26 }} />
              <Typography
                fontSize={14}
                color="GrayText"
                fontWeight="400"
                marginTop="3px"
              >
                {group.name}
              </Typography>
            </AccordionSummary>
            {group.tasks.map((task: ITaskCard, i: number) => (
              <AccordionDetails
                key={task.id}
                sx={{ padding: " 0 8px 8px 8px", display: "flex" }}
              >
                <Checkbox
                  disableRipple
                  size="small"
                  icon={<RadioButtonUncheckedIcon sx={{ fontSize: 20 }} />}
                  checkedIcon={<CheckCircleRoundedIcon sx={{ fontSize: 20 }} />}
                  checked={task.status === ETaskStatus.Complete}
                  onClick={() => handleUpdateStatus(task.id, task.status)}
                  sx={{
                    span: {
                      "&:hover": {
                        backgroundColor: "transparent !important"
                      }
                    }
                  }}
                />
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    fontSize={14}
                    color="BlackText"
                    fontWeight="600"
                    padding={"9px 9px 9px 0"}
                    onClick={() =>
                      isHasPermission ? setTaskUpdate(task) : null
                    }
                  >
                    {task.title}
                  </Typography>
                  <Box display="flex">
                    {task.personAssigned && (
                      <>
                        <Typography fontSize={12} color={"#858585"}>
                          Giao cho
                        </Typography>
                        <Typography sx={{ margin: "0 4px", fontSize: 12 }}>
                          {task.personAssigned?.fullName} {" - "}
                        </Typography>
                      </>
                    )}
                    <Typography fontSize={12} color={"#858585"}>
                      Deadline:
                    </Typography>
                    <Typography sx={{ margin: "0 4px", fontSize: 12 }}>
                      {task.deadLine
                        ? dayjs(task.deadLine).format("HH:mm DD/MM/YYYY")
                        : "Không thời hạn"}
                    </Typography>
                  </Box>
                </Box>
              </AccordionDetails>
            ))}
          </Accordion>
        ))}
      {taskUpdate && (
        <CreateTaskModal
          open={taskUpdate !== undefined}
          taskId={taskUpdate.id}
          isUpdate={true}
          hasDocument={false}
          workflowId={taskUpdate.boardId}
          onCreateSuccess={newTaskcard => {
            setTaskUpdate(undefined);
            getTasksAxios.request(
              TaskService.getPageChildren({
                CurrentPage: 1,
                PageSize: 10,
                BoardId: BoardId,
                TaskCardId: TaskCardId
              })
            );
          }}
          handleClose={() => setTaskUpdate(undefined)}
        />
      )}
    </Wrapper>
  );
};

export default ChildrenTask;
