import { UniqueIdentifier } from "@dnd-kit/core";
import { TagInput } from "../../WorkflowManagement/components/TagInput/TagInput";
import { ITag } from "../../../models/user";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

interface IProps {
  workflowId: UniqueIdentifier;
  value: ITag[];
  setValue: (newValue: ITag[]) => void;
}

interface DataForm {
  assigned: ITag[];
}
const TrackerFilter = ({ workflowId, value, setValue }: IProps) => {
  const validationSchema = Yup.object().shape({}) as any;
  const {
    control,
    getValues,
    trigger,
    formState: { errors }
  } = useForm<DataForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    setValue(getValues("assigned"));
  }, [getValues("assigned")]);

  return (
    <form style={{ minWidth: "220px" }}>
      <TagInput
        name={"assigned"}
        store={"workflow"}
        storeId={workflowId}
        trigger={trigger}
        errors={errors}
        defaultValues={value}
        control={control}
        isError={false}
        placeholder={"Tìm kiếm người nhận nhiệm vụ"}
      />
    </form>
  );
};

export default TrackerFilter;
