import { AddRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { DATETIME_FORMAT2 } from "../../../../config/constants";
import { useApi } from "../../../../hooks/useApi.hook";
import { IDocument, IDocumentType, IReceiver } from "../../../../models/docs";
import DocsService from "../../../../services/api/docs.service";
import { Wrapper } from "./styles";
import IconDelete from "../../../../assets/images/common/trash_bin.svg";
import IconNotes from "../../../../assets/images/common/Notes.svg";
import IconEdit from "../../../../assets/images/common/document_edit.svg";
import IconInfo from "../../../../assets/images/common/info_square.svg";
import EmptyProject from "../../../../assets/images/common/empty_project.svg";
import { ImageCustom } from "../../../../components";
import {
  documentStatusColor,
  documentStatusTitle
} from "../../../../models/common/models.enum";
import DocsContent from "../DocsContent/DocsContent";

import CreateDocumentPopup from "../CreateDocumentPopup";
import { IAttachmentFile } from "../../../../models/Task";
import UploadFileService from "../../../../services/api/uploadFile.service";
import { toggleMessage } from "../../../../components/Toast/Toast";
import { toggleConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";

import CreateDocumentTypePopup from "../CreateDocumentTypePopup";
import DocumentTypePopup from "../DocumentTypePopup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  minHeight: "100vh",
  height: "100vh",
  alignContent: "center",
  overflow: "auto",
  backgroundColor: "transparent"
};

const boxStyle = {
  backgroundColor: "#f1f5f6",
  padding: "14px",
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  borderRadius: "8px"
};

type Props = {
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  isCreateType: boolean;
  isViewType: boolean;
  isUpdateType: boolean;
  isDeleteType: boolean;
};

const DocsAdminView = ({
  isCreate,
  isDelete,
  isUpdate,
  isCreateType,
  isUpdateType,
  isDeleteType,
  isViewType
}: Props) => {
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDocType, setOpenDocType] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [documentTypes, setDocumentTypes] = useState<IDocumentType[] | null>(
    null
  );

  const getPage = useApi<IDocument[]>({ isFetch: true, isPaging: true });
  const getSingle = useApi<IDocument>({ isFetch: true });
  const uploadFile = useApi<IAttachmentFile[]>({});
  const create = useApi<IDocument>({});
  const update = useApi<IDocument>({});
  const deleteDoc = useApi<IDocument>({});
  const getDocumentType = useApi<IDocumentType[]>({ isFetch: true });
  const getDocumentTypeNotPermission = useApi<IDocumentType[]>({
    isFetch: true
  });

  function fetchDocumentType() {
    getDocumentType.request(
      DocsService.getDocumentType({
        CurrentPage: 1,
        PageSize: 10
      }).then(res => {
        if (res.success) setDocumentTypes(res.items);
      })
    );
  }

  function fetchDocumentTypeNotPermission() {
    getDocumentTypeNotPermission.request(
      DocsService.getDocumentTypeNotPermission({
        CurrentPage: 1,
        PageSize: 10
      }).then(res => {
        if (res.success) setDocumentTypes(res.items);
      })
    );
  }

  const handleOpen = (id: string) => {
    getSingle.request(DocsService.getSingle(id));
    fetchDocumentType();
    setOpen(true);
  };

  const handleCreateOpen = () => {
    //! ERROR
    if (!isCreateType && documentTypes && documentTypes?.length === 0) {
      toggleMessage({
        type: "error",
        message:
          "Bạn không thể tạo văn bản khi chưa có loại văn bản, nếu không có quyền thao tác vui lòng liên hệ admin để được cấp quyền"
      });
    } else {
      setOpenCreate(true);
    }
  };

  function fetchData(pageIndex?: number) {
    getPage.request(
      DocsService.getPageByAdmin({
        CurrentPage: pageIndex ?? 1,
        PageSize: 10
      })
    );
  }

  function refreshDocumentType() {
    if (isViewType) {
      fetchDocumentType();
    } else {
      fetchDocumentTypeNotPermission();
    }
  }

  useEffect(() => {
    fetchData();
    refreshDocumentType();
  }, []);

  function onCreate(request: any) {
    if (request.reciver) {
      const list = (request.reciver as any[]).map(
        r =>
          ({
            id: "idEdit" in r ? r.idEdit : null,
            userOrDepartmentId: r.id,
            type: r.type,
            userName: r.username
          }) as IReceiver
      );
      request = { ...request, reciver: list };
    }

    isEdit
      ? update.request(
          DocsService.update(request).then(res => {
            if (res.success) {
              toggleMessage({
                type: "success",
                message: res?.message ?? "Cập nhật thành công"
              });
              // handleReset();
              fetchData(getPage.pagging?.currentPage);
              setOpen(false);
            } else {
              if (request.attachmentFiles) {
                uploadFile.request(
                  UploadFileService.deleteFiles(request.attachmentFiles)
                );
              }
            }
          })
        )
      : create.request(
          DocsService.create(request).then(res => {
            if (res.success) {
              toggleMessage({
                type: "success",
                message: res?.message ?? "Thêm mới thành công"
              });
              // handleReset();
              fetchData();
              setOpenCreate(false);
            } else {
              if (request.attachmentFiles) {
                uploadFile.request(
                  UploadFileService.deleteFiles(request.attachmentFiles)
                );
              }
            }
          })
        );
  }

  const createOnSubmit = (request: any) => {
    var files: File[] = [];
    var oldFiles: IAttachmentFile[] = [];
    if (request.attachmentFiles) {
      request.attachmentFiles.map((f: any) =>
        f instanceof File ? files.push(f) : oldFiles.push(f)
      );
      if (files.length) {
        const filesData = new FormData();
        filesData.append("FolderName", "Document");
        files
          .filter(e => e.size > 0)
          .forEach(file => filesData.append("Files", file));
        uploadFile.request(
          UploadFileService.uploadFile(filesData).then(response => {
            if (response.success && response.data) {
              const req = {
                ...request,
                attachmentFiles: [...oldFiles, ...response.data]
              };
              onCreate(req);
            } else {
              toggleMessage({
                type: "error",
                message: "Upload file xảy ra lỗi, vui lòng thử lại"
              });
            }
          })
        );
      } else {
        onCreate({ ...request, attachmentFiles: oldFiles });
      }
    } else {
      onCreate(request);
    }
  };

  const handleCloseDocumentType = (isChange: boolean) => {
    setOpenDocType(false);
    if (isChange) {
      refreshDocumentType();
    }
  };

  const handleCloseCreateDocumentType = (isChange: boolean) => {
    setOpenCreate(false);
    if (isChange) {
      refreshDocumentType();
    }
  };

  return (
    <Wrapper>
      {getPage?.data?.length ? (
        <Box>
          <Box className="header-box">
            {isViewType && (
              <Button
                variant="outlined"
                color="info"
                size="small"
                sx={{ borderRadius: "8px" }}
                endIcon={<KeyboardArrowDownRounded />}
                onClick={() => setOpenDocType(true)}
              >
                Loại văn bản
              </Button>
            )}
            {isCreate && (
              <Button
                startIcon={<AddRounded />}
                variant="contained"
                size="small"
                sx={{ borderRadius: "8px", marginLeft: "12px" }}
                onClick={() => handleCreateOpen()}
              >
                Tạo văn bản mới
              </Button>
            )}
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={"36%"}>CÔNG VĂN</TableCell>
                <TableCell width={"14%"}>TRẠNG THÁI</TableCell>
                <TableCell width={"18%"}>LOẠI</TableCell>
                <TableCell width={"17%"}>THỜI GIAN</TableCell>
                <TableCell width={"15%"}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {getPage?.data.map(item => (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography variant="subtitle2" className="text-overflow">
                      [{item.numberIdentifier}] {item.title}
                    </Typography>
                    <div
                      className="text-overflow"
                      dangerouslySetInnerHTML={{
                        __html: item.description.length ? item.description : ""
                      }}
                    ></div>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        backgroundColor: documentStatusColor(item.status).color,
                        color: "white",
                        padding: "4px 10px",
                        borderRadius: "8px",
                        textAlign: "center"
                      }}
                    >
                      {documentStatusTitle(item.status)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {item.documentType?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Ngày ban hành</Typography>
                    <Typography variant="subtitle2">
                      {item.dateOfIssue
                        ? moment(item.dateOfIssue).format(DATETIME_FORMAT2)
                        : "Không có thông tin"}
                    </Typography>
                    <Typography variant="body2">Ngày hết hạn</Typography>
                    <Typography variant="subtitle2">
                      {item.documentExpirationDate
                        ? moment(item.documentExpirationDate).format(
                            DATETIME_FORMAT2
                          )
                        : "Không có thông tin"}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {isUpdate && (
                      <Tooltip title={"Chỉnh sửa thông tin"}>
                        <Button
                          color="info"
                          onClick={() => {
                            setIsEdit(true);
                            handleOpen(item.id);
                          }}
                        >
                          <ImageCustom
                            src={IconEdit}
                            width="24px"
                            height="24px"
                          />
                        </Button>
                      </Tooltip>
                    )}

                    <Tooltip title={"Xem chi tiết"} sx={{ margin: "0 20px" }}>
                      <Button
                        color="info"
                        onClick={() => {
                          setIsEdit(false);
                          handleOpen(item.id);
                        }}
                      >
                        <ImageCustom
                          src={IconInfo}
                          width="24px"
                          height="24px"
                        />
                      </Button>
                    </Tooltip>

                    {isDelete && (
                      <Tooltip title={"Xoá văn bản"}>
                        <Button
                          color="info"
                          onClick={() => {
                            toggleConfirmModal({
                              title: `Bạn có chắc chắn muốn xoá văn bản này?`,
                              content: `Bạn sẽ không thể hoàn tác vụ sau khi xác nhận`,
                              onSubmit: async () => {
                                deleteDoc.request(
                                  DocsService.deleteDoc(item.id).then(res => {
                                    if (res.success) {
                                      toggleMessage({
                                        type: "success",
                                        message:
                                          res?.message ?? "Cập nhật thành công"
                                      });
                                      fetchData(getPage.pagging?.currentPage);
                                    } else {
                                      toggleMessage({
                                        type: "error",
                                        message:
                                          res?.error ??
                                          "Đã xảy ra lỗi, vui lòng thử lại"
                                      });
                                    }
                                  })
                                );
                              },
                              submitText: "Xác nhận",
                              type: "warning-red"
                            });
                          }}
                        >
                          <ImageCustom
                            src={IconDelete}
                            width="24px"
                            height="24px"
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {getPage.pagging && getPage.pagging.totalPages > 1 && (
            <Box
              sx={{
                margin: "12px 18px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center"
              }}
            >
              <Typography>
                {1 + 10 * (getPage.pagging.currentPage - 1)} -{" "}
                {getPage.pagging.currentPage * 10 < getPage.pagging.totalCount!
                  ? getPage.pagging.currentPage * 10
                  : getPage.pagging.totalCount!}{" "}
                / {getPage.pagging.totalCount}
              </Typography>
              <Pagination
                count={getPage.pagging.totalPages}
                showFirstButton={getPage.pagging.totalPages > 2}
                showLastButton={getPage.pagging.totalPages > 2}
                shape="rounded"
                page={getPage.pagging.currentPage ?? 0}
                onChange={(_, v) => fetchData(v)}
              />
            </Box>
          )}

          <Modal open={open} onClose={() => setOpen(false)}>
            <Box sx={style} onClick={() => (isEdit ? {} : setOpen(false))}>
              <Box
                sx={{
                  backgroundColor: "white",
                  width: "55vw",
                  padding: "68px",
                  height: "auto",
                  margin: "auto"
                }}
              >
                {getSingle.loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                    <Typography marginLeft="18px">
                      Đang tải dữ liệu..
                    </Typography>
                  </Box>
                ) : (
                  getSingle.data &&
                  (isEdit ? (
                    <CreateDocumentPopup
                      handleClose={() =>
                        isEdit ? setOpen(false) : setOpenCreate(false)
                      }
                      handleOnSubmit={createOnSubmit}
                      isLoading={isEdit ? update.loading : create.loading}
                      editItem={getSingle.data}
                      documentTypes={getDocumentType.data ?? []}
                    />
                  ) : (
                    <DocsContent item={getSingle.data} />
                  ))
                )}
              </Box>
            </Box>
          </Modal>
        </Box>
      ) : (
        <Box>
          <Box display="flex">
            <Box flexGrow={1} />
            {isViewType && (
              <Button
                variant="outlined"
                color="info"
                endIcon={<KeyboardArrowDownRounded />}
                onClick={() => setOpenDocType(true)}
              >
                Loại văn bản
              </Button>
            )}
          </Box>
          <Box className="empty-box">
            <ImageCustom src={EmptyProject} width="auto" height="170px" />
            <Typography variant="h6" marginTop="28px">
              Chưa có công văn nào được tạo
            </Typography>
            {isCreate && (
              <Button
                startIcon={<AddRounded />}
                variant="contained"
                sx={{ marginTop: "28px" }}
                onClick={() => handleCreateOpen()}
              >
                Tạo văn bản mới
              </Button>
            )}
          </Box>
        </Box>
      )}
      <Modal open={openCreate} onClose={() => setOpenCreate(false)}>
        <Box sx={style}>
          {documentTypes && documentTypes.length > 0 ? (
            <CreateDocumentPopup
              handleClose={() => setOpenCreate(false)}
              handleOnSubmit={createOnSubmit}
              isLoading={create.loading}
              documentTypes={documentTypes}
            />
          ) : (
            <CreateDocumentTypePopup
              handleClose={handleCloseCreateDocumentType}
            />
          )}
        </Box>
      </Modal>

      <Modal open={openDocType} onClose={() => setOpenDocType(false)}>
        <Box sx={style}>
          {documentTypes && (
            <DocumentTypePopup
              data={documentTypes}
              style={boxStyle}
              handleClose={handleCloseDocumentType}
              isCreate={isCreateType}
              isEdit={isUpdateType}
              isDelete={isDeleteType}
            />
          )}
        </Box>
      </Modal>
    </Wrapper>
  );
};

export default DocsAdminView;
