import {
  Divider,
  styled,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip
} from "@mui/material";
import { Avatar, ControlForm, Modal } from "../../components";
import { Wrapper } from "./styles";
import variableStyles from "../../theme/variable-styles";
import { useEffect, useRef, useState } from "react";
import {
  IChangePasswordByUserReq,
  IEditInfoByUserReq,
  IUser
} from "../../models/user";
import CircleIcon from "@mui/icons-material/Circle";
import { useApi } from "../../hooks/useApi.hook";
import UserService from "../../services/api/user.service";
import { authTokens } from "../../services/services";
import RolesTag from "../Member/components/RolesTag";
import { UserAction } from "../../models/common/models.enum";
import AccountLayout from "../Member/components/AccountLayout";
import { Helmet } from "react-helmet";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CakeRoundedIcon from "@mui/icons-material/CakeRounded";
import CommonHandle from "../../common/handles/common.handles";
import { BorderColorRounded } from "@mui/icons-material";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import InputForm from "../../components/controls/InputForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DATE_FORMAT2 } from "../../config/constants";
import { toggleMessage } from "../../components/Toast/Toast";
import EditAvatar from "../Member/components/EditAvatar";
import { toggleConfirmModal } from "../../components/ConfirmModal/ConfirmModal";
import UploadFileService from "../../services/api/uploadFile.service";
import { IAttachmentFile } from "../../models/Task";

type Props = {};

const Tittle = styled("div")(({ theme }) => ({
  fontWeight: "bold",
  padding: "8px 0",
  color: "GrayText"
}));

const AccountInfo = (props: Props) => {
  const [item, setItem] = useState<IUser | null>(null);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [userAction, setUserAction] = useState<UserAction | null>(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const inputRef = useRef<any>(null);
  const uploadFile = useApi<IAttachmentFile[]>({});

  const getInfo = useApi<IUser>({});
  const changePass = useApi<IUser>({});
  const userUpdate = useApi<IUser>({});
  // const [file, setFile] = useState<File | null>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const temp = e.target.files[0];
      toggleConfirmModal({
        title: `Bạn muốn thay đổi ảnh đại diện?`,
        content: (
          <Avatar
            width="80px"
            height="80px"
            src={temp && URL.createObjectURL(temp)}
            isUrl={false}
          />
        ),
        onSubmit: async () => {
          const formData = new FormData();
          formData.append("FolderName", "User");
          formData.append("Files", temp);
          uploadFile.request(
            UploadFileService.uploadFile(formData).then(response => {
              if (response.success) {
                const editRequest: IEditInfoByUserReq = {
                  id: item?.id ?? "",
                  phoneNumber: item?.phoneNumber ?? "",
                  address: item?.address ?? "",
                  avatar: response.data[0].path
                };
                editUser(editRequest);
              } else {
                toggleMessage({
                  type: "error",
                  message: uploadFile?.error ?? "Đã xảy ra lỗi"
                });
              }
            })
          );
        },
        submitText: "Xác nhận",
        type: "warning"
      });
    }
  };

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  function fetchData() {
    if (userInfo) {
      getInfo.request(UserService.getSingle(userInfo.id));
    }
  }

  useEffect(() => {
    if (userInfo) fetchData();
  }, [userInfo]);

  function setValueText(item: IUser) {
    setValue("phoneNumber", item.phoneNumber);
    setValue("address", item.address);
    setValue("birthDay", CommonHandle.formatDate(item?.birthDay, DATE_FORMAT2));
  }

  function editUser(req: IEditInfoByUserReq) {
    userUpdate.request(
      UserService.updateUser(req).then(res => {
        console.log(res);
        if (res.success) {
          toggleMessage({
            type: "success",
            message: "Cập nhật thành công"
          });
          setOpenEdit(false);
          fetchData();
        } else {
          console.log(res.message);
          toggleMessage({
            type: "error",
            message: res?.message ?? "Đã xảy ra lỗi"
          });
        }
      })
    );
  }

  useEffect(() => {
    if (getInfo.data) {
      setItem(getInfo.data);
      // setFile(getInfo.data.avatar);
      setValueText(getInfo.data);
    }
  }, [getInfo.data]);

  const EmptyBox = () => {
    return (
      <Box
        sx={{
          margin: "12px 0",
          backgroundColor: variableStyles.grey200,
          width: "auto",
          height: "48px",
          textAlign: "center",
          alignContent: "center",
          borderRadius: "5px"
        }}
      >
        Không có thông tin
      </Box>
    );
  };

  const validate = (): any => {
    switch (userAction) {
      case UserAction.ChangePassword:
        return {
          newPassword: Yup.string().required(`${"Vui lòng nhập mật khẩu mới"}`),
          oldPassword: Yup.string().required(`${"Vui lòng nhập mật khẩu cũ"}`)
        };
      default:
        break;
    }
  };

  const validationSchema = Yup.object().shape(validate()) as any;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (request: any) => {
    switch (userAction) {
      case UserAction.ChangePassword:
        const req: IChangePasswordByUserReq = {
          oldPassword: request.oldPassword,
          newPassword: request.newPassword
        };
        changePass.request(
          UserService.updatePasswordByUser(req).then(res => {
            console.log(res);
            if (res.success) {
              toggleMessage({
                type: "success",
                message: "Cập nhật thành công"
              });
              setOpenEdit(false);
            } else {
              console.log(res.message);
              toggleMessage({
                type: "error",
                message: res?.message ?? "Đã xảy ra lỗi"
              });
            }
          })
        );
        break;

      case UserAction.EditInformation:
        toggleConfirmModal({
          title: `Bạn muốn thay đổi?`,
          content: `Bạn có chắc chắc muốn thay đổi thông tin cá nhân?`,
          onSubmit: async () => {
            const editReq: IEditInfoByUserReq = {
              id: item?.id ?? "",
              // birthDay: request.birthDay,
              phoneNumber: request.phoneNumber,
              address: request.address,
              avatar: item?.avatar ?? ""
            };
            editUser(editReq);
          },
          submitText: "Xác nhận",
          type: "warning-red"
        });
        break;

      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title> Quản lý tài khoản | Cá nhân</title>
      </Helmet>
      <Box display="flex" margin="32px" alignItems="center">
        <Typography
          className="header-text"
          variant="subtitle1"
          color="GrayText"
          marginRight="20px"
        >
          THÔNG TIN TÀI KHOẢN
        </Typography>
        <Divider sx={{ flexGrow: 1 }} />
      </Box>

      <div className="content-container">
        <div className="account-identity">
          <Box marginTop="16px">
            <EditAvatar
              file={null}
              avatar={item?.avatar}
              size="80px"
              onButtonClick={() => inputRef.current.click()}
              handleFileChange={handleFileChange}
              inputRef={inputRef}
            />
          </Box>

          <div style={{ marginLeft: "48px", flexGrow: 1 }}>
            <RolesTag roles={item?.role} />
            <Typography
              variant="subtitle2"
              style={{ fontSize: "20px", fontWeight: "500", marginTop: "6px" }}
            >
              {item?.fullName}
            </Typography>
            <Box display="flex" color="GrayText" marginTop="4px">
              <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
                {item?.userName}
              </Typography>
              <CircleIcon sx={{ fontSize: "4px", margin: "10px" }} />
              <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
                {item?.code ?? "Chưa có mã nhân viên"}
              </Typography>
            </Box>
            <Button
              className="button-text"
              size="small"
              color="info"
              startIcon={<CachedRoundedIcon />}
              sx={{ marginTop: "4px" }}
              onClick={() => {
                setUserAction(UserAction.ChangePassword);
                setOpenEdit(true);
              }}
            >
              Đổi mật khẩu
            </Button>
          </div>
        </div>

        <div className="account-info">
          <div style={{ flexGrow: 1 }}>
            <Box className="info-row">
              <Tittle>CHỨC DANH</Tittle>
              <Typography className="body1-text" variant="body1">
                {item?.position}
              </Typography>
            </Box>

            <Box className="info-row">
              <Tittle>EMAIL</Tittle>
              <Typography className="body1-text" variant="body1">
                {item?.email}
              </Typography>
            </Box>

            <Box className="info-row">
              <Tittle>QUẢN LÝ TRỰC TIẾP</Tittle>
              <Typography
                className="subtitle1-text"
                variant="subtitle1"
                color="#3091F3"
              >
                {item?.manager?.fullName ?? "Chưa có người quản lý"}
              </Typography>
            </Box>

            <Box className="info-row">
              <Box display="flex" alignItems="center">
                <Tittle>THÔNG TIN LIÊN HỆ</Tittle>
                <Tooltip title="Chỉnh sửa thông tin" placement="right">
                  <IconButton
                    onClick={() => {
                      setUserAction(UserAction.EditInformation);
                      setOpenEdit(true);
                    }}
                  >
                    <BorderColorRounded
                      color="info"
                      sx={{ fontSize: "18px", marginLeft: "4px" }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box className="row-infomation">
                <LocalPhoneRoundedIcon />
                <Typography variant="body1">
                  {item?.phoneNumber?.length
                    ? item?.phoneNumber
                    : "Không có thông tin"}
                </Typography>
              </Box>
              <Box className="row-infomation">
                <CakeRoundedIcon />
                <Typography variant="body1">
                  {item?.birthDay
                    ? CommonHandle.formatDate(item?.birthDay)
                    : "Không có thông tin"}
                </Typography>
              </Box>
              <Box className="row-infomation">
                <LocationOnRoundedIcon />
                <Typography variant="body1">
                  {item?.address ?? "Không có thông tin"}
                </Typography>
              </Box>
            </Box>
          </div>

          <div style={{ flexGrow: 1 }}>
            <Box className="info-row">
              <Tittle>SỐ NGÀY PHÉP</Tittle>
              {item?.totalTimeOff ? (
                <Typography className="body1-text" variant="body1">
                  {item?.totalTimeOff} ngày
                </Typography>
              ) : (
                EmptyBox()
              )}
            </Box>

            <Box className="info-row">
              <Tittle>NHÓM ({item?.departments.length})</Tittle>
              {item?.departments.length ? (
                <Box>
                  {item?.departments.map(department => (
                    <Box key={department.id} className="deparment-card">
                      <Typography
                        className="subtitle1-text"
                        variant="subtitle1"
                        color="#3091F3"
                      >
                        {department.name}
                      </Typography>
                      <Typography className="body1-text" variant="body1">
                        {department.userName}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                EmptyBox()
              )}
            </Box>

            <Box className="info-row">
              <Tittle>NHÂN VIÊN TRỰC TIẾP ({item?.staff.length})</Tittle>
              {item?.staff.length ? (
                <Box>
                  {item?.staff.map(user => (
                    <Box key={user.id} margin="12px 0">
                      <AccountLayout
                        name={user.fullName}
                        tag={user.userName}
                        position={user?.position ?? ""}
                        avatar={user?.avatar}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                EmptyBox()
              )}
            </Box>
          </div>
        </div>
      </div>

      {item && (
        <Modal
          title={userAction ?? ""}
          textSubmit="Cập nhật"
          textClose="Huỷ bỏ"
          open={openEdit}
          onSubmit={handleSubmit(onSubmit)}
          onClose={() => {
            setOpenEdit(false);
            setUserAction(null);
            setValueText(item);
            reset();
          }}
          loadingState={changePass.loading}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            {userAction === UserAction.ChangePassword && (
              <>
                <ControlForm
                  title={"Mật khẩu cũ"}
                  isRequired
                  classname="brand-form"
                >
                  <InputForm
                    placeholder={"Nhập mật khẩu cũ"}
                    required
                    name={"oldPassword"}
                    errors={errors}
                    control={control}
                    size="small"
                    type="password"
                    disabled={changePass.loading}
                    onKeyDown={e => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                </ControlForm>
                <ControlForm
                  title={"Mật khẩu mới"}
                  isRequired
                  classname="brand-form"
                >
                  <InputForm
                    placeholder={"Nhập mật khẩu mới"}
                    required
                    name={"newPassword"}
                    errors={errors}
                    control={control}
                    size="small"
                    type="password"
                    disabled={changePass.loading}
                    onKeyDown={e => {
                      if (e.key === " ") {
                        e.preventDefault();
                      }
                    }}
                  />
                </ControlForm>
              </>
            )}
            {userAction === UserAction.EditInformation && (
              <>
                <ControlForm title={"Số điện thoại"} classname="brand-form">
                  <InputForm
                    placeholder={"Nhập số điện thoại"}
                    required
                    name={"phoneNumber"}
                    errors={errors}
                    control={control}
                    size="small"
                    type="tel"
                  />
                </ControlForm>
                {/* <ControlForm title={"Ngày sinh"} classname="brand-form">
                  <InputForm
                    placeholder={"Nhập ngày sinh"}
                    required
                    name={"birthDay"}
                    errors={errors}
                    control={control}
                    type="date"
                  />
                </ControlForm> */}
                <ControlForm title={"Địa chỉ"} classname="brand-form">
                  <InputForm
                    placeholder={"Nhập địa chỉ"}
                    required
                    name={"address"}
                    errors={errors}
                    control={control}
                    size="small"
                  />
                </ControlForm>
              </>
            )}
          </form>
        </Modal>
      )}
    </Wrapper>
  );
};

export default AccountInfo;
