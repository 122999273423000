// const pxToRem = (value: number) => {
//   return `${value / 100}rem`;
// };

import { useMediaQuery } from "@mui/material";

const pxToRem = (value: number) => {
  return `${value / 100}rem`;
};

export type SizeProps = {
  fontsize: { xs: number; sm: number; md: number; lg: number; xl: number };
  lineheight?: { xs: number; sm: number; md: number; lg: number; xl: number };
  fontWeight?: { xs: number; sm: number; md: number; lg: number; xl: number };
};

const responsiveFontSizes = (props: SizeProps) => {
  return {
    "@media (min-width:0px) and (max-width: 424.98px)": {
      fontSize: pxToRem(props.fontsize.xs),
      lineHeight: props.lineheight && pxToRem(props.lineheight.xs),
      fontWeight: props.fontWeight && props.fontWeight.xs
    },
    "@media (min-width:425px) and (max-width: 767.98px)": {
      fontSize: pxToRem(props.fontsize.sm),
      lineHeight: props.lineheight && pxToRem(props.lineheight.sm),
      fontWeight: props.fontWeight && props.fontWeight.sm
    },
    "@media (min-width:768px) and (max-width: 1023.98px)": {
      fontSize: `${props.fontsize.md}px !important`,
      lineHeight: props.lineheight && `${props.lineheight.md}px !important`,
      fontWeight: props.fontWeight && props.fontWeight.md
    },
    "@media (min-width:1024px) and (max-width: 1439.98px)": {
      fontSize: `${props.fontsize.lg}px !important`,
      lineHeight: props.lineheight && `${props.lineheight.lg}px !important`,
      fontWeight: props.fontWeight && props.fontWeight.lg
    },
    "@media (min-width:1440px)": {
      fontSize: `${props.fontsize.xl}px !important`,
      lineHeight: props.lineheight && `${props.lineheight.xl}px !important`,
      fontWeight: props.fontWeight && props.fontWeight.xl
    }
  };
};

export { pxToRem, responsiveFontSizes };
