import { styled } from "@mui/material";
import { responsiveFontSizes } from "../../../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  display: "flex",
  maxHeight: "calc(100vh - 52px)",
  overflow: "hidden",
  width: "100%",
  "& .search-container": { margin: "32px 24px" },

  "& .content-list": {
    width: "28%",

    "& .content-list-item": {
      maxHeight: "calc(100vh - 52px - 100px)",
      overflow: "auto",
      justifyItems: "center",
      "& .list-item": {
        width: "100%",
        padding: "18px",
        backgroundColor: "white",
        borderBottom: "1px solid #C7C8C9"
      }
    }
  },

  "& .content-page": {
    // width: "72%",
    flexGrow: 1,
    backgroundColor: "white",
    minHeight: "calc(100vh - 52px)",
    height: "auto",
    padding: "62px 72px",
    overflow: "auto",
    borderLeft: "2px solid #C7C8C9",
    "& .empty-box": {
      justifyItems: "center",
      margin: "25vh"
    }
  },

  "@media (max-width: 868px)": {
    svg: {
      width: "12px",
      height: "12px"
    },
    "& .search-container": { margin: "0 12px 16px 12px" },
    "& .information-container": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 14,
          sm: 14,
          md: 16,
          lg: 16,
          xl: 18
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .content-list": {
      width: "100%",
      "& .content-list-item": {
        "& .list-item": {
          // padding: 0,
          "& .item-title": {
            ...responsiveFontSizes({
              fontsize: {
                xs: 14,
                sm: 14,
                md: 16,
                lg: 16,
                xl: 18
              },
              lineheight: {
                xs: 22.59,
                sm: 22.59,
                md: 22.59,
                lg: 22.59,
                xl: 22.59
              }
            })
          },
          "& .item-time": {
            ...responsiveFontSizes({
              fontsize: {
                xs: 12,
                sm: 13,
                md: 14,
                lg: 16,
                xl: 18
              },
              lineheight: {
                xs: 22.59,
                sm: 22.59,
                md: 22.59,
                lg: 22.59,
                xl: 22.59
              }
            })
          }
        }
      }
    },

    "& .login-box-left": {
      width: "400px",
      padding: "40px",
      svg: {
        width: "20px",
        height: "20px"
      },
      "&. sign-up-question": {
        ...responsiveFontSizes({
          fontsize: {
            xs: 14,
            sm: 16,
            md: 18,
            lg: 18,
            xl: 18
          },
          lineheight: {
            xs: 22.59,
            sm: 22.59,
            md: 22.59,
            lg: 22.59,
            xl: 22.59
          }
        })
      },
      button: {
        ...responsiveFontSizes({
          fontsize: {
            xs: 14,
            sm: 16,
            md: 18,
            lg: 18,
            xl: 18
          },
          lineheight: {
            xs: 22.59,
            sm: 22.59,
            md: 22.59,
            lg: 22.59,
            xl: 22.59
          }
        })
      }
    },
    "& .content-page": {
      display: "none"
    }
  }
}));
