import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppBar, Box, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { accountSidebarItems } from "../../routes";
import { SidebarWrapper } from "./styles";
import { IUser } from "../../models/user";
import { authTokens } from "../../services/services";
import { useApi } from "../../hooks/useApi.hook";
import { IFeaturePermission } from "../../models/user/models.roles";
import RoleService from "../../services/api/role.service";
import { theme } from "../../theme/theme";

type Props = {
  onMobileClose: () => void;
  openMobile: boolean | undefined;
};

const AccountSidebar = ({ onMobileClose, openMobile }: Props) => {
  const location = useLocation();
  const [notExpanded, setNotExpanded] = useState<boolean>(false);
  useState<HTMLButtonElement | null>(null);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const role = useApi<IFeaturePermission[]>({ isFetch: true });
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const sidebarData = accountSidebarItems(role.data);
  const [title, setTitle] = useState(sidebarData[0].text ?? "");

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo)
      role.request(RoleService.getFeaturePemissionByUserId(userInfo.id));
  }, [userInfo]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(`${"Please enter username"}`),
    password: Yup.string().required(`${"Please enter password"}`)
  }) as any;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      username: null,
      password: null
    },
    resolver: yupResolver(validationSchema)
  });

  return (
    <>
      <SidebarWrapper notexpanded={notExpanded}>
        {matches ? (
          <AppBar>
            <Toolbar sx={{ backgroundColor: "#22272D" }}>
              <Typography className="sidebar-title" variant="h1">
                {title}
              </Typography>
              <ul
                className={`sidebar-menu ${notExpanded ? "notExpaned" : ""} scrollbar-small`}
              >
                {sidebarData.map((sidebar, index) => (
                  <li key={index} onClick={() => setTitle(sidebar.text)}>
                    <Link
                      className={`sidebar-item ${location.pathname === sidebar?.href ? "active" : ""}`}
                      to={sidebar.href || "/"}
                    >
                      <div
                        className={`icon-box ${location.pathname === sidebar?.href ? "active" : ""}`}
                      >
                        {location.pathname === sidebar?.href
                          ? sidebar?.icon?.active
                          : sidebar?.icon?.default}
                      </div>
                      {/* <Typography
                        variant="overline"
                        sx={{
                          fontWeight: 500,
                          fontSize: "12px",
                          marginTop: "8px"
                        }}
                      >
                        {sidebar?.text}
                      </Typography> */}
                    </Link>
                  </li>
                ))}
              </ul>
            </Toolbar>
          </AppBar>
        ) : (
          <Box className="sidebar-box">
            <ul
              className={`sidebar-menu ${notExpanded ? "notExpanded" : ""} scrollbar-small`}
            >
              {sidebarData.map((sidebar, index) => (
                <li key={index}>
                  <Link
                    className={`sidebar-item ${location.pathname === sidebar?.href ? "active" : ""}`}
                    to={sidebar.href || "/"}
                  >
                    <div
                      className={`icon-box ${location.pathname === sidebar?.href ? "active" : ""}`}
                    >
                      {location.pathname === sidebar?.href
                        ? sidebar?.icon?.active
                        : sidebar?.icon?.default}
                    </div>
                    <Typography
                      variant="overline"
                      sx={{
                        fontWeight: 500,
                        fontSize: "12px",
                        marginTop: "8px"
                      }}
                    >
                      {sidebar?.text}
                    </Typography>
                  </Link>
                </li>
              ))}
            </ul>
          </Box>
        )}
      </SidebarWrapper>
    </>
  );
};

export default AccountSidebar;
