import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Typography
} from "@mui/material";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { GroupPanelWrapper } from "./styles";
import WorkflowItem from "../WorkflowItem/workflow_item";
import { IWorkflowByTypeResponse } from "../../../../models/workflow";

interface Props {
  workflow: IWorkflowByTypeResponse;
  tabCode: string;
}

const GroupPanel = ({ workflow, tabCode }: Props) => {
  return (
    <GroupPanelWrapper>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropUpIcon sx={{ height: 24, width: 24 }} />}
          aria-controls="panel1-content"
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Avatar
              sx={{
                bgcolor: "#267CDE",
                width: 30,
                height: 30,
                fontSize: 13,
                fontWeight: 700
              }}
            >
              {workflow.typeBoard
                .split(" ")
                .splice(0, 2)
                .map(e => e.charAt(0))
                .join("")}
            </Avatar>
            <Typography className="group-panel-title" fontSize={19}>
              {workflow.typeBoard}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display={"flex"} flexWrap={"wrap"} flexDirection={"row"} gap={3}>
            {workflow.boards.map(item => (
              <WorkflowItem key={item.id} workflow={item} tabCode={tabCode} />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider></Divider>
    </GroupPanelWrapper>
  );
};

export default GroupPanel;
