import { Box, Typography } from "@mui/material";
import React from "react";
import variableStyles from "../../../../../theme/variable-styles";

type Props = {};

const ContentLayout = (props: {
  title: string;
  action?: React.ReactNode | undefined;
  children?: React.ReactNode | undefined;
}) => {
  return (
    <Box sx={{ marginBottom: "24px" }}>
      <Box display={{ display: "flex" }}>
        <Typography
          variant="overline"
          sx={{
            marginTop: "4px",
            flexGrow: 1,
            color: "#7ac395",
            fontWeight: 700,
            lineHeight: 1.5,
            fontSize: 12,
            letterSpacing: 1.1,
            textTransform: "uppercase"
          }}
        >
          {props.title}
        </Typography>
        {props.action}
      </Box>
      {props.children}
    </Box>
  );
};

export default ContentLayout;
