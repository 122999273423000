import { styled } from "@mui/material";
import variableStyles from "../../../../theme/variable-styles";
import { responsiveFontSizes } from "../../../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  "& .MuiTableCell-head": {
    backgroundColor: "white",
    padding: "8px 16px "
  },
  // "& .MuiTableRow-root:hover": {
  //   backgroundColor: variableStyles.NaturalColor50
  // },
  // "& .MuiTableRow-root.Mui-selected": {
  //   backgroundColor: "#FEFAFA",
  //   borderLeft: "2px solid #ED3E47"
  // },
  // "& .MuiTableRow-root.Mui-selected:hover": {
  //   backgroundColor: variableStyles.Error50
  // },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #F2F2F2"
  },
  "@media (max-width: 868px)": {
    svg: { fontSize: 24 },
    "& .MuiTableCell-head": {
      "& .header-table": {
        ...responsiveFontSizes({
          fontsize: {
            xs: 12,
            sm: 12,
            md: 14,
            lg: 14,
            xl: 14
          },
          lineheight: {
            xs: 22.59,
            sm: 22.59,
            md: 22.59,
            lg: 22.59,
            xl: 22.59
          }
        })
      }
    },
    "& .tablerow-content": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 12,
          sm: 12,
          md: 14,
          lg: 14,
          xl: 14
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    }
  }
}));
