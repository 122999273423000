import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet";
import {
  Button,
  Chip,
  Pagination,
  Tab,
  TableHead,
  Tabs,
  Typography
} from "@mui/material";
import useAxios from "../../components/UseAxios/useAxios";
import TimeOffTypeService from "../../services/api/timeofftype.service";
import { useCallback, useEffect, useState } from "react";
import { ITimeOffType } from "../../models/timeofftype";
import { toggleMessage } from "../../components/Toast/Toast";
import {
  ETimeOffOperation,
  FeatureModel,
  TimeOffTypePermissionModel
} from "../../models/common/models.enum";
import { IFeaturePermission } from "../../models/user/models.roles";
import RoleService from "../../services/api/role.service";
import { authTokens } from "../../services/services";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import DeleteTimeOffTypeModal from "./components/DeleteTimeOffTypeModal/DeleteTimeOffTypeModal";
import { IUser } from "../../models/user";
import { I } from "@fullcalendar/core/internal-common";
import UpdateMailCCModal from "./components/UpdateMailCCModal/UpdateMailCCModal";
import CreateTimeOffTypeModal from "./components/CreateTimeOffTypeModal/CreateTimeOffTypeModal";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <div className="mt-1">{children}</div>}
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
    lineHeight: 1.5
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    lineHeight: 1.5
  }
}));

export default function TimeOffType() {
  const [value, setValue] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [permission, setPermission] = useState<
    IFeaturePermission | undefined
  >();
  const [updateTimeOffType, setUpdateTimeOffType] = useState<{
    open: boolean;
    type?: ITimeOffType;
  } | null>(null);
  const [deleteTimeOffType, setDeleteTimeOffType] = useState<{
    open: boolean;
    type: ITimeOffType;
  } | null>(null);
  const [openUpdateMailCC, setOpenUpdateMailCC] = useState<boolean>(false);
  const [allType, setAllType] = useState<ITimeOffType[]>([]);
  const [allMailCC, setMailCC] = useState<IUser[]>([]);
  const getPermissionAxios = useAxios<IFeaturePermission[]>({
    loading: "OnRequest"
  });
  const getTimeOffTypePage = useAxios<ITimeOffType[]>({
    loading: "OnRequest"
  });
  const getAllMailCC = useAxios<IUser[]>({
    loading: "OnRequest"
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    authTokens.getUser().then(user => {
      getPermissionAxios.request(
        RoleService.getFeaturePemissionByUserId(user?.id ?? "")
      );
    });
  }, []);

  const fetchTimeOffTypePage = useCallback(() => {
    if (permission?.permissions.includes(TimeOffTypePermissionModel.View))
      getTimeOffTypePage.request(
        TimeOffTypeService.getPage({ CurrentPage: page, PageSize: rowsPerPage })
      );
  }, [permission]);

  const fetchMailCCPage = useCallback(() => {
    if (permission?.permissions.includes(TimeOffTypePermissionModel.MailCC))
      getAllMailCC.request(TimeOffTypeService.getMailCCDefault());
  }, [permission]);

  useEffect(() => {
    fetchTimeOffTypePage();
  }, [permission, rowsPerPage, page]);

  useEffect(() => {
    fetchMailCCPage();
  }, [permission]);

  useEffect(() => {
    if (getPermissionAxios.isSuccess && getPermissionAxios.data) {
      setPermission(
        getPermissionAxios.data.find(
          e => e.feature === FeatureModel.TimeOffType
        )
      );
    }
    if (getPermissionAxios.error) {
      toggleMessage({
        type: "error",
        message: getPermissionAxios.error?.message ?? ""
      });
    }
  }, [getPermissionAxios.isSuccess, getPermissionAxios.error]);

  useEffect(() => {
    if (getTimeOffTypePage.isSuccess && getTimeOffTypePage.data) {
      setAllType(getTimeOffTypePage.data);
    }
    if (getTimeOffTypePage.error) {
      toggleMessage({
        type: "error",
        message: getTimeOffTypePage.error.message ?? ""
      });
    }
  }, [
    getTimeOffTypePage.isSuccess,
    getTimeOffTypePage.data,
    getTimeOffTypePage.error
  ]);
  useEffect(() => {
    if (getAllMailCC.isSuccess && getAllMailCC.data) {
      setMailCC(getAllMailCC.data);
    }
    if (getAllMailCC.error) {
      toggleMessage({
        type: "error",
        message: getAllMailCC.error.message ?? ""
      });
    }
  }, [getAllMailCC.isSuccess, getAllMailCC.data, getAllMailCC.error]);

  const emptyRows = allType.length === 0;

  const handleCreate = () => {
    setUpdateTimeOffType({ open: true });
  };

  const handleEdit = (type: ITimeOffType) => {
    setUpdateTimeOffType({ open: true, type: type });
  };

  const handleDelete = (type: ITimeOffType) => {
    setDeleteTimeOffType({ open: true, type: type });
  };

  return (
    <Box className={"flex flex-col"} sx={{ padding: "12px 26px 8px 26px" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Loại nghỉ phép"}</title>
      </Helmet>

      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example"
        >
          <Tab value={1} label="Loại nghỉ phép" sx={{ fontSize: 14 }}></Tab>
          {permission &&
            permission.permissions.includes(
              TimeOffTypePermissionModel.MailCC
            ) && <Tab value={2} label="MailCC" sx={{ fontSize: 14 }} />}
        </Tabs>

        <LoadingOverlayWrapper
          active={getPermissionAxios.isLoading || getTimeOffTypePage.isLoading}
          spinner
          text="Đang tải dữ liệu..."
        >
          {permission && (
            <TabPanel value={value} index={1}>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell colSpan={3}>
                        Tên loại nghỉ phép
                      </StyledTableCell>
                      <StyledTableCell colSpan={8} align="left">
                        Cách tính
                      </StyledTableCell>
                      {(permission.permissions.includes(
                        TimeOffTypePermissionModel.Update
                      ) ||
                        permission.permissions.includes(
                          TimeOffTypePermissionModel.Delete
                        )) && (
                        <StyledTableCell
                          colSpan={3}
                          align="right"
                        ></StyledTableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allType.map(row => (
                      <TableRow key={row.name}>
                        <StyledTableCell colSpan={3}>
                          {row.name}
                        </StyledTableCell>
                        <StyledTableCell colSpan={8} align="left">
                          {row.operation === ETimeOffOperation.Minus &&
                            "Tính phép năm"}
                          {row.operation === ETimeOffOperation.NotOperation &&
                            "Không tính phép năm"}
                        </StyledTableCell>
                        {(permission.permissions.includes(
                          TimeOffTypePermissionModel.Update
                        ) ||
                          permission.permissions.includes(
                            TimeOffTypePermissionModel.Delete
                          )) && (
                          <StyledTableCell colSpan={3} align="right">
                            <Box className="flex flex-row justify-center gap-2">
                              {permission.permissions.includes(
                                TimeOffTypePermissionModel.Delete
                              ) && (
                                <Button
                                  className="hover:bg-gray-200 hover:text-black"
                                  color="error"
                                  sx={{ fontSize: 14, fontWeight: 600 }}
                                  onClick={() => handleDelete(row)}
                                >
                                  Xoá
                                </Button>
                              )}
                              {permission.permissions.includes(
                                TimeOffTypePermissionModel.Update
                              ) && (
                                <Button
                                  className="hover:bg-gray-200 hover:text-black "
                                  color="secondary"
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 600
                                  }}
                                  onClick={() => handleEdit(row)}
                                >
                                  Chỉnh sửa
                                </Button>
                              )}
                            </Box>
                          </StyledTableCell>
                        )}
                      </TableRow>
                    ))}
                    {emptyRows && (
                      <TableRow style={{ height: 53 * 14 }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      {getTimeOffTypePage.paging &&
                        getTimeOffTypePage.paging.totalPages > 1 && (
                          <Pagination
                            count={getTimeOffTypePage.paging.totalPages}
                            page={getTimeOffTypePage.paging.currentPage}
                            sx={{ padding: 1 }}
                            onChange={(e, value) => setPage(value)}
                          />
                        )}
                    </TableRow>
                  </TableFooter>

                  {permission?.permissions.includes(
                    TimeOffTypePermissionModel.Create
                  ) && (
                    <TableContainer className="table-caption">
                      <Button
                        className="rounded-lg float-right"
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                          marginRight: 1,
                          marginY: 2,
                          fontSize: 14,
                          height: "auto"
                        }}
                        onClick={handleCreate}
                      >
                        Thêm loại nghỉ phép
                      </Button>
                    </TableContainer>
                  )}
                </Table>
              </TableContainer>
            </TabPanel>
          )}
        </LoadingOverlayWrapper>
        {updateTimeOffType && (
          <CreateTimeOffTypeModal
            open={updateTimeOffType?.open}
            type={updateTimeOffType?.type}
            onCreateSuccess={() => {
              fetchTimeOffTypePage();
              setUpdateTimeOffType(null);
            }}
            handleClose={() => {
              setUpdateTimeOffType(null);
            }}
          />
        )}
        {deleteTimeOffType && (
          <DeleteTimeOffTypeModal
            open={deleteTimeOffType?.open}
            type={deleteTimeOffType.type}
            onDeleteSuccess={() => {
              fetchTimeOffTypePage();
              setDeleteTimeOffType(null);
            }}
            handleClose={() => {
              setDeleteTimeOffType(null);
            }}
          />
        )}

        <TabPanel value={value} index={2}>
          {allMailCC && allMailCC.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                component={"span"}
                variant="body1"
                sx={{ marginY: 2, fontSize: 14 }}
              >
                Người được cc mặc định:
              </Typography>
              <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                {allMailCC.map(user => (
                  <Chip
                    key={user.id}
                    label={user.email}
                    sx={{ margin: 1, fontSize: 14 }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {(!allMailCC || allMailCC.length === 0) && (
            <Box
              height={300}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              textAlign={"center"}
            >
              <Typography component={"span"} width={"100%"} fontSize={13}>
                Danh sách trống
              </Typography>
            </Box>
          )}
          <Button
            className="rounded-lg "
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginY: 2, fontSize: 14, height: "auto" }}
            onClick={() => setOpenUpdateMailCC(true)}
          >
            Thêm mail
          </Button>

          <UpdateMailCCModal
            open={openUpdateMailCC}
            cc={allMailCC}
            onUpdateSuccess={() => {
              fetchMailCCPage();
              setOpenUpdateMailCC(false);
            }}
            handleClose={() => {
              setOpenUpdateMailCC(false);
            }}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
