import { Popover } from "@mui/material";
import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";
import { responsiveFontSizes } from "../../utils/responsive";

interface SidebarWrapperProps {
  notexpanded: boolean;
}

export const SidebarWrapper = styled("div")<SidebarWrapperProps>(
  ({ theme, notexpanded }) => ({
    flex: "0 0 auto",
    position: "relative",
    borderRight: `1px solid ${variableStyles.NaturalColor200}`,
    "@keyframes hidden": {
      "0%": {
        opacity: "1"
      },
      "50%": {
        opacity: "1"
      },
      "100%": {
        display: "none",
        opacity: "0"
      }
    },
    "& .sidebar-box": {
      backgroundColor: "#22272D",
      transition: "all 0.25s linear",
      height: "100vh",
      overflow: "hidden",
      width: "110px",
      textAlign: "center"
    },

    "& .sidebar-menu": {
      overflow: "auto",
      "&.notExpanded": {
        transition: "all 0s linear 0.2s",
        height: "calc(100vh - 222px)"
      },
      "& .sidebar-item": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
        position: "relative",
        fontSize: "11px",
        color: "#65686C",
        "&:hover": {
          color: "white",
          fontWeight: "bold"
        },
        "&.active": {
          color: "white",
          fontWeight: "bold"
        },
        "& .icon-box": {
          "&:hover, &.Mui-focusVisible": { color: "white" },
          "&.active": { color: "white" }
        }
      }
    },
    "@media (max-width: 868px)": {
      "& .sidebar-title": {
        flexGrow: 1,
        ...responsiveFontSizes({
          fontsize: {
            xs: 14,
            sm: 14,
            md: 16,
            lg: 16,
            xl: 16
          }
        })
      },
      "& .sidebar-menu": {
        display: "flex",
        "& .sidebar-item": {
          svg: {
            ...responsiveFontSizes({
              fontsize: {
                xs: 28,
                sm: 28,
                md: 29,
                lg: 29,
                xl: 29
              }
            })
          }
        }
      }
    }
  })
);
