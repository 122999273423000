import vi from "date-fns/locale/vi";
import { useEffect, useMemo, useState } from "react";
import { DateRangePicker, Range, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Popover from "@mui/material/Popover";
import PopupState, {
  bindTrigger,
  bindPopover,
  bindToggle
} from "material-ui-popup-state";
import moment from "moment";
import { set } from "lodash";
import { DATE_FORMAT, DATETIME_FORMAT } from "../../../config/constants";

interface IProps {
  SelectionRange: Range | undefined;
  onChange: (value: Range | undefined) => void;
}

const DateTimeFilter = ({ SelectionRange, onChange }: IProps) => {
  const [currentRange, setCurrentRange] = useState<Range | undefined>(
    undefined
  );

  useEffect(() => {
    setCurrentRange(SelectionRange);
  }, [SelectionRange]);

  const handleSelect = (ranges: RangeKeyDict) => {
    setCurrentRange(ranges.selection);
  };

  const isDefault = useMemo(() => {
    if (!SelectionRange) return true;
    return false;
  }, [SelectionRange]);

  const rangePreview = useMemo(() => {
    if (!SelectionRange && isDefault && !currentRange)
      return "Tìm theo thời gian";
    if (
      SelectionRange!.startDate?.toDateString() ===
      SelectionRange!.endDate?.toDateString()
    )
      return moment(SelectionRange!.startDate).format(DATE_FORMAT);
    return (
      "từ " +
      moment(SelectionRange!.startDate).format(DATE_FORMAT) +
      " - " +
      "đến " +
      moment(SelectionRange!.endDate).format(DATE_FORMAT)
    );
  }, [isDefault, SelectionRange]);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {popupState => (
        <div>
          <button
            {...bindTrigger(popupState)}
            type="button"
            className="inline-flex  items-center font-medium text-center text-white bg-blue-400 rounded-full focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            style={{
              padding: "9.5px 28px",
              inlineSize: "max-content",
              fontSize: 14
            }}
          >
            {rangePreview}
          </button>

          <Popover
            {...bindPopover(popupState)}
            onClose={() => {
              bindPopover(popupState).onClose();
              setCurrentRange(SelectionRange);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            sx={{
              marginTop: "4px",
              "& .rdrDateRangePickerWrapper": {
                display: "flex",
                flexDirection: "column-reverse",
                "& .rdrDefinedRangesWrapper": {
                  borderRight: "none",
                  "& .rdrStaticRanges": {
                    display: "none"
                  },
                  "& .rdrInputRanges": {
                    display: "none"
                  }
                }
              }
            }}
          >
            <DateRangePicker
              editableDateInputs={false}
              direction={"horizontal"}
              startDatePlaceholder="Bắt đầu"
              endDatePlaceholder="Kết thúc"
              dateDisplayFormat="dd/MM/yyyy"
              footerContent={
                <div className="flex flex-row justify-between">
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentRange(undefined);
                      onChange(undefined);
                      popupState.close();
                    }}
                    className="inline-flex items-center font-medium text-center text-white bg-red-400 rounded-lg focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                    style={{ fontSize: 14, padding: "4px 12px", margin: "8px" }}
                  >
                    Reset
                  </button>
                  {currentRange && (
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          JSON.stringify(currentRange) !==
                          JSON.stringify(SelectionRange)
                        )
                          onChange(currentRange);
                        popupState.close();
                      }}
                      className="inline-flex items-center font-medium text-center text-white bg-green-400 rounded-lg focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                      style={{
                        fontSize: 14,
                        padding: "4px 12px",
                        margin: "8px"
                      }}
                    >
                      Xác nhận
                    </button>
                  )}
                </div>
              }
              ranges={[
                {
                  startDate: currentRange?.startDate ?? new Date(),
                  endDate: currentRange?.endDate ?? new Date(),
                  key: "selection"
                }
              ]}
              onChange={handleSelect}
              locale={vi}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  );
};
export default DateTimeFilter;
