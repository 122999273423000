import { Box, Button, Container, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FORBIDDEN from "../assets/images/common/triangle_warning.svg";
import ERROR from "../assets/images/common/clien_err.jpg";
import { ImageCustom } from "../components";
import authTokens, { USER_FCM_TOKEN_KEY } from "../services/local/auth-tokens";
import AuthService from "../services/api/auth.service";
import { ROUTE_PATH } from "../common/constants/app.constant";

type Props = {};

const Forbidden = (props: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        width: "100%",
        height: "100vh"
      }}
      title="403 Page No Access | Miko Admin"
    >
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          // justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "cyan"
          justifyContent: "space-evenly",
          margin: "0 200px"
        }}
      >
        <Box sx={{}}>
          <Typography
            style={{
              fontFamily: "IstokWeb",
              fontWeight: 700,
              fontSize: "170px",
              backgroundImage:
                "-webkit-linear-gradient(-45deg, #29435D 30%, #3091F3 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent"
            }}
          >
            403
          </Typography>
          <Box>
            <Typography
              style={{
                fontFamily: "IstokWeb",
                fontWeight: 700,
                fontSize: "34px"
              }}
            >
              Forbiden Error!
            </Typography>
            <Typography
              style={{
                fontFamily: "IstokWeb",
                fontWeight: 400,
                fontSize: "20px"
              }}
            >
              Không có quyền truy cập
            </Typography>
            <Box display={"flex"}>
              <Button
                to="/"
                size="small"
                variant="outlined"
                component={RouterLink}
                color="info"
                sx={{
                  borderRadius: "8px",
                  marginTop: "40px",
                  color: "#3091F3",
                  width: "150px",
                  height: "48px",
                  marginRight: "16px"
                }}
              >
                Trở về trang chủ
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  borderRadius: "8px",
                  marginTop: "40px",
                  backgroundColor: "#3091F3",
                  width: "150px",
                  height: "48px"
                }}
                onClick={async () => {
                  const token = localStorage.getItem(USER_FCM_TOKEN_KEY);
                  if (token) {
                    AuthService.logout(token);
                  }
                  await authTokens.clear();
                  if (window.location.href.indexOf(ROUTE_PATH.LOGIN) === -1) {
                    window.location.href = ROUTE_PATH.LOGIN;
                  }
                }}
              >
                Đăng nhập lại
              </Button>
            </Box>
          </Box>
        </Box>
        <ImageCustom src={ERROR} width={"70vh"} height="70vh" />
      </Box>

      <Box
        sx={{
          flex: "0 1 auto",
          backgroundColor: "#22272D",
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "100px",
          textAlign: "end",
          alignItems: "center"
        }}
      >
        <Box sx={{ opacity: "40%" }}>
          <ImageCustom src={FORBIDDEN} width={180} height={180} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: "white",
              fontSize: "20px",
              lineHeight: 1.8
            }}
          >
            Không có quyền truy cập vào chức năng này hoặc phân quyền sử dụng đã
            bị thay đổi
          </Typography>
          <Typography
            sx={{
              color: "white",
              lineHeight: 1.8,
              fontSize: "20px"
            }}
          >
            Vui lòng đăng nhập lại hoặc liên hệ quản trị viên để được cấp quyền
            sử dụng
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Forbidden;
