import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";

export const AccountLayoutRoot = styled("div")(() => ({
  display: "flex",
  height: "100vh",
  overflow: "hidden",
  width: "100%",
  "@media (max-width: 868px)": {
    // "& .account-sidebar": { display: "none" }
  }
}));
