import {
  IBasePagingRes,
  IBaseResponseModel
} from "../../models/common/models.type";
import api from "../remote/baseApi";
import ApiEndpoints from "../remote/apiEndpoints";
import { envConfig } from "../../config/env.config";
import { IGetUsersReq } from "../../models/user";
import { IDocument, IDocumentType } from "../../models/docs";
import { IGetPageReq } from "../../models/common/models.request";

const API = ApiEndpoints.DOCS;
const baseURL = envConfig.API_ENDPOINT;

const getPageByUser = (data: IGetUsersReq): Promise<IBasePagingRes<any>> => {
  return api.get(baseURL, API.GET_PAGE_BY_USER.url, {
    params: data
  });
};

const getPageByAdmin = (
  data: IGetUsersReq
): Promise<IBasePagingRes<IDocument>> => {
  return api.get(baseURL, API.GET_PAGE_BY_ADMIN.url, {
    params: data
  });
};

const create = (
  data: IDocument
): Promise<IBaseResponseModel<IBasePagingRes<any>>> => {
  return api.post(baseURL, API.CREATE.url, data);
};

const createDocumentType = (data: {
  name: string;
  isActive: boolean;
}): Promise<IBaseResponseModel<IBaseResponseModel<any>>> => {
  return api.post(baseURL, API.CREATE_DOCUMENT_TYPE.url, data);
};

const getSingle = (id: string): Promise<IBaseResponseModel<IDocument>> => {
  return api.get(baseURL, `${API.GET_SINGLE.url}/${id}`);
};

const getDocumentType = (
  data: IGetPageReq
): Promise<IBasePagingRes<IDocumentType>> => {
  return api.get(baseURL, API.GET_DOCUMENT_TYPE.url, {
    params: data
  });
};

const getDocumentTypeNotPermission = (
  data: IGetPageReq
): Promise<IBasePagingRes<IDocumentType>> => {
  return api.get(baseURL, API.GET_DOCUMENT_TYPE_NOT_PERMISSION.url, {
    params: data
  });
};

const update = (data: IDocument): Promise<IBaseResponseModel<IDocument>> => {
  return api.put(baseURL, API.UPDATE.url, data);
};

const updateRead = (id: string): Promise<IBaseResponseModel<IDocument>> => {
  return api.put(baseURL, API.UPDATE_READ.url, { documentId: id });
};

const deleteDoc = (id: string): Promise<IBaseResponseModel<any>> => {
  return api.delete(baseURL, `${API.DELETE.url}/${id}`);
};
const deleteDocType = (id: string): Promise<IBaseResponseModel<any>> => {
  return api.delete(baseURL, `${API.DELETE_DOCUMENT_TYPE.url}/${id}`);
};

const updateDocType = (
  data: IDocumentType
): Promise<IBaseResponseModel<IDocumentType>> => {
  return api.put(baseURL, API.UPDATE_DOCUMENT_TYPE.url, data);
};

const DocsService = {
  getPageByUser,
  getPageByAdmin,
  getSingle,
  create,
  createDocumentType,
  getDocumentType,
  getDocumentTypeNotPermission,
  update,
  updateDocType,
  updateRead,
  deleteDoc,
  deleteDocType
};

export default DocsService;
