import { Box, CircularProgress, Typography } from "@mui/material";
import DvrIcon from "@mui/icons-material/Dvr";
import { useSearchParams } from "react-router-dom";
import { Roles } from "../../models/common/models.enum";
import { ITabItem } from "../WorkflowManagement/workflow_manage";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { useCallback, useEffect, useMemo, useState } from "react";
import WorkflowService from "../../services/api/workflow.service";
import { toggleMessage } from "../../components/Toast/Toast";
import Manage from "./Task";
import ProjectMagagerial from "./Managerial";
import StageService from "../../services/api/stage.service";
import {
  IBoardFilter,
  IRoleBoard,
  IStage,
  IStageByWorkflowResponse,
  IUpdateStagePositionRequest
} from "../../models/Stage";
import {
  ITaskCard,
  ITaskCardUpdatePositionRequest,
  ITaskUpdatePositionResponse
} from "../../models/Task";
import TaskService from "../../services/api/task.service";
import useAxios from "../../components/UseAxios/useAxios";
import { Helmet } from "react-helmet";
import { ManageProjectWrapper } from "./styles";
import useNavigateSearch from "../../components/NavigateSearch";
import { Modal } from "../../components";
import NameFilter from "./Filter/NameFilter";
import DateTimeFilter from "./Filter/DateTimeFilter";
import StageFilter from "./Filter/StageFilter";
import { Range } from "react-date-range";
import TrackerFilter from "./Filter/TrackerFilter";
import { ITag } from "../../models/user";
import BoardProject1 from "./BoardProject/board_project";

export const tabItems = (role?: Roles): ITabItem[] => {
  const BOARD: ITabItem = {
    href: ROUTE_PATH.PROJECTS,
    title: "Dạng bảng",
    code: "Dạng bảng"
  };
  const SNARE: ITabItem = {
    href: ROUTE_PATH.MANAGER,
    title: "Dạng lưới",
    code: "Dạng lưới"
  };
  const MORE: ITabItem = {
    href: ROUTE_PATH.PROJECTS_MANAGERIAL,
    title: "Xem thêm",
    code: "Xem thêm"
  };

  return [BOARD, SNARE, MORE];
};

const ManageProject = () => {
  const [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const tabItemsData = tabItems();
  const reloadChannel = useMemo(() => new BroadcastChannel("reload-board"), []);
  const [tabIndex, setTabIndex] = useState(0);
  const [openShutWorkflowDialog, setOpenShutWorkflowDialog] = useState(false);

  const [workflow, setWorkflow] = useState<IStageByWorkflowResponse | null>(
    null
  );
  const [stages, setStages] = useState<IStage[]>([]);

  ///filter
  const [taskNameSearch, setTaskNameSearch] = useState<string | undefined>(
    undefined
  );
  const [taskTrackerSearch, setTaskTrackerSearch] = useState<ITag[]>([]);
  const [taskDateSearch, setTaskDateSearch] = useState<Range | undefined>(
    undefined
  );
  const [stageSearch, setStageSearch] = useState<IStage | undefined>(undefined);

  const [filter, setFilter] = useState<IBoardFilter>({ boardId: "" });
  ///

  const workflowAxios = useAxios<IStageByWorkflowResponse>({
    loading: "OnRequest"
  });
  const shutWorkflowAxios = useAxios<string>({ loading: "OnRequest" });
  const updateStagePositionAxios = useAxios<IStageByWorkflowResponse>({
    loading: "onCreate"
  });
  const updateTaskPositionAxios = useAxios<ITaskUpdatePositionResponse>({
    loading: "onCreate"
  });
  useEffect(() => {
    if (taskNameSearch === "") {
      setTaskNameSearch(undefined);

      if (filter.KeySearch) {
        setStagesUpdate(true);
      }
    }
  }, [taskNameSearch, filter.KeySearch]);

  const [stagesUpdate, setStagesUpdate] = useState(true);

  const roleBoardByUser: IRoleBoard | undefined = useMemo(() => {
    if (workflow) {
      return workflow.roleBoardByUser;
    }
  }, [workflow]);

  useEffect(() => {
    reloadChannel.addEventListener("message", event => setStagesUpdate(true));
    return () => {
      reloadChannel.removeEventListener("message", event =>
        setStagesUpdate(true)
      );
      reloadChannel.close();
    };
  }, [reloadChannel]);

  useEffect(() => {
    if (workflowAxios.error)
      toggleMessage({
        type: "error",
        message: workflowAxios.error.message ?? ""
      });
  }, [workflowAxios.error]);

  useEffect(() => {
    if (workflowAxios.data) {
      setWorkflow(workflowAxios.data);
      setStages(workflowAxios.data.workflows);
    }
  }, [workflowAxios.data]);

  useEffect(() => {
    if (updateTaskPositionAxios.isSuccess && updateTaskPositionAxios.data) {
      let newStages = structuredClone(stages);
      let oldIndex = stages.findIndex(
        e => e.id === updateTaskPositionAxios.data!.oldWorkflow.id
      );
      newStages[oldIndex].taskCards =
        updateTaskPositionAxios.data.oldWorkflow.taskCards;

      let newIndex = stages.findIndex(
        e => e.id === updateTaskPositionAxios.data!.newWorkflow.id
      );
      newStages[newIndex].taskCards =
        updateTaskPositionAxios.data.newWorkflow.taskCards;

      setStages(newStages);

      toggleMessage({
        type: "success",
        message: updateTaskPositionAxios.message ?? "Cập nhật thành công"
      });
    }
    if (updateTaskPositionAxios.error) {
      setStagesUpdate(true);
      toggleMessage({
        type: "error",
        message: updateTaskPositionAxios.error.message ?? ""
      });
    }
  }, [
    updateTaskPositionAxios.isSuccess,
    updateTaskPositionAxios.error,
    updateTaskPositionAxios.data,
    updateTaskPositionAxios.message
  ]);

  useEffect(() => {
    if (updateStagePositionAxios.isSuccess) {
      setStagesUpdate(true);
      toggleMessage({
        type: "success",
        message: updateStagePositionAxios.message ?? "Cập nhật thành công"
      });
    }
    if (updateStagePositionAxios.error) {
      setStagesUpdate(true);
      toggleMessage({
        type: "error",
        message: updateStagePositionAxios.error.message ?? ""
      });
    }
  }, [
    updateStagePositionAxios.error,
    updateStagePositionAxios.isSuccess,
    updateStagePositionAxios.message
  ]);

  useEffect(() => {
    if (shutWorkflowAxios.isSuccess)
      navigateSearch(ROUTE_PATH.WORKFLOWS, {
        tab: searchParams.get("tab") ?? "availability"
      });
    if (shutWorkflowAxios.error)
      toggleMessage({
        type: "error",
        message: shutWorkflowAxios.error.message ?? ""
      });
  }, [
    shutWorkflowAxios.isSuccess,
    shutWorkflowAxios.error,
    navigateSearch,
    searchParams
  ]);

  const getWorkflow = useCallback(async () => {
    const filter: IBoardFilter = {
      boardId: searchParams.get("id") as string
    };
    if (stageSearch) filter["WorkflowId"] = stageSearch.id;
    if (taskNameSearch) filter["KeySearch"] = taskNameSearch;
    if (taskDateSearch) {
      filter["StartDate"] = taskDateSearch.startDate;
      if (
        taskDateSearch.startDate?.toDateString() !==
        taskDateSearch.endDate?.toDateString()
      )
        filter["EndDate"] = taskDateSearch.endDate;
    }
    if (taskTrackerSearch.length > 0) {
      filter["PersonAssignedIds"] = taskTrackerSearch.map(e => e.id).join(",");
    }
    workflowAxios.reset();
    workflowAxios.request(StageService.getStage(filter));
    setFilter(filter);
    setStagesUpdate(false);
  }, [
    taskNameSearch,
    taskDateSearch,
    stageSearch,
    searchParams.get("id"),
    taskTrackerSearch,
    workflowAxios
  ]);

  const handleConfirmShutWorkflow = () => {
    shutWorkflowAxios.request(
      WorkflowService.closeWorkflow(workflow!.board.id)
    );
  };
  const handleCloseShutWorkflowDialog = () => {
    setOpenShutWorkflowDialog(false);
  };

  const handleUpdateStagePosition = async (
    current: IStage,
    newPosition: IStage
  ) => {
    const request: IUpdateStagePositionRequest = {
      BoardId: workflow!.board.id,
      WorkflowMoveId: current.id,
      NewPosition: newPosition.position
    };
    updateStagePositionAxios.request(StageService.updateStagePosition(request));
  };

  const handleUpdateTaskPosition = async (
    task: ITaskCard,
    stage: IStage,
    index: number
  ) => {
    const request: ITaskCardUpdatePositionRequest = {
      WorkflowId: stage.id,
      TaskCardMoveId: task.id,
      NewPosition: index
    };

    updateTaskPositionAxios.request(TaskService.updateTaskPosition(request));
  };

  useEffect(() => {
    setWorkflow(null);
    setStages([]);
    setStagesUpdate(true);
  }, [searchParams.get("id")]);

  useEffect(() => {
    if (stagesUpdate) getWorkflow();
  }, [stagesUpdate, getWorkflow]);

  if (workflowAxios.isLoading === true && stages.length === 0)
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <ManageProjectWrapper active={false}>
      {workflow && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{workflow.board.name}</title>
        </Helmet>
      )}
      {workflow && (
        <Box
          className="board-project-header flex"
          sx={{
            height: {
              xs: 140,
              md: 80
            },
            gap: "4px"
          }}
        >
          <Box className="board-project-icon" sx={{ width: { md: "50px" } }}>
            <DvrIcon sx={{ color: "#AAAAAA", width: 18, height: 18 }} />
          </Box>
          <Box className="board-project-header-info" sx={{ flexGrow: 1 }}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              className="board-project-title"
              sx={{ flexGrow: 1 }}
            >
              {workflow && (
                <Typography
                  fontSize={22}
                  fontWeight={600}
                  lineHeight={1}
                  className="prevent-select"
                  sx={{
                    lineHeight: "18.8px",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                  }}
                >
                  {workflow.board.name}
                </Typography>
              )}
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{ height: 40 }}
              ></Box>
            </Box>

            <Box
              className="board-project-tabs"
              sx={{
                height: 30
              }}
            >
              <ul className="tab-list">
                {workflow &&
                  tabItemsData.map((tabItem, i) => (
                    <li
                      key={tabItem.code}
                      onClick={() => setTabIndex(i)}
                      className={`prevent-select ${tabIndex === i ? "active" : ""}`}
                    >
                      {tabItem.title}
                    </li>
                  ))}
              </ul>
            </Box>
          </Box>
        </Box>
      )}
      {/* {workflow && roleBoardByUser && tabIndex === 0 && (
        <BoardProject
          workflow={workflow.board}
          role={roleBoardByUser}
          stages={stages}
          tasks={tasks}
          onCloseWorkflow={id => setOpenShutWorkflowDialog(true)}
          onReload={() => setStagesUpdate(true)}s
          onUpdateStagePosition={handleUpdateStagePosition}
          onUpdateTaskPosition={handleUpdateTaskPosition}
          onStagesChanged={newStages => setStages(newStages)}
          onTasksChanged={newTasks => setTasks(newTasks)}
        />
      )} */}
      {workflow && roleBoardByUser && tabIndex === 0 && (
        <BoardProject1
          stages={stages.map((stage: IStage) => {
            stage.taskCards.sort((a, b) => a.position - b.position);
            return stage;
          })}
          setStages={newStage => {
            console.log("updated");
            setStages(newStage);
          }}
          workflow={workflow.board}
          role={roleBoardByUser}
          onCloseWorkflow={id => setOpenShutWorkflowDialog(true)}
          onReload={() => setStagesUpdate(true)}
          onUpdateStagePosition={handleUpdateStagePosition}
          onUpdateTaskPosition={handleUpdateTaskPosition}
          filter={
            <Box
              className="flex flex-row align-middle overflow-auto"
              sx={{ gap: "16px", padding: "8px" }}
            >
              <NameFilter
                placeholder={"Tìm kiếm công việc.."}
                value={taskNameSearch}
                setValue={setTaskNameSearch}
                onSearch={() => {
                  if (taskNameSearch?.trim()) setStagesUpdate(true);
                }}
                onClear={() => {
                  setTaskNameSearch(undefined);
                  setStagesUpdate(true);
                }}
              />
              <TrackerFilter
                workflowId={workflow!.board.id!}
                value={taskTrackerSearch}
                setValue={trackers => {
                  if (trackers !== taskTrackerSearch) {
                    setTaskTrackerSearch(trackers);
                    setStagesUpdate(true);
                  }
                }}
              />
              <DateTimeFilter
                SelectionRange={taskDateSearch}
                onChange={newRange => {
                  let isChanged = newRange !== taskDateSearch;
                  if (isChanged) {
                    setTaskDateSearch(newRange);
                    setStagesUpdate(true);
                  }
                }}
              />
              <StageFilter
                stages={stages}
                value={stageSearch}
                onChange={newStage => {
                  let isChanged = stageSearch?.id !== newStage?.id;
                  if (isChanged) {
                    setStageSearch(newStage);
                    setStagesUpdate(true);
                  }
                }}
              />
            </Box>
          }
        />
      )}
      {workflow && roleBoardByUser && tabIndex === 1 && (
        <Manage workflow={workflow} role={roleBoardByUser} />
      )}
      {tabIndex === 2 && <ProjectMagagerial id={workflow?.board.id!} />}
      {workflow && (
        <Modal
          title={"Xác nhận đóng luồng công việc"}
          textSubmit={"Xác nhận"}
          textClose="Huỷ bỏ"
          open={openShutWorkflowDialog}
          onSubmit={handleConfirmShutWorkflow}
          disabledSubmit={shutWorkflowAxios.isLoading}
          onClose={handleCloseShutWorkflowDialog}
        >
          {"Bạn có chắc chắn muốn đóng luồng công việc này?"}
        </Modal>
      )}
    </ManageProjectWrapper>
  );
};

export default ManageProject;
