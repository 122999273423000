import { Box, Divider, Typography } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { ReactNode } from "react";
import BasicMenu, { IMenuItem } from "../../../../../components/Menu/BasicMenu";
import { EStageType } from "../../../../../models/common/models.enum";
import { IStage, IRoleBoard } from "../../../../../models/Stage";
import { BorderLinearProgress } from "../../../../WorkflowManagement/components/WorkflowItem/border_linear_progress";

interface StageColumnProps {
  name: string;
  dragItem: boolean;
  children: ReactNode;
  stage: IStage;
  roleWorkflow: IRoleBoard;
  isShowMenu?: boolean;
  onLeftAdd?: () => void;
  onRightAdd?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
}

const StageColumn = ({
  name,
  dragItem,
  children,
  stage,
  roleWorkflow,
  isShowMenu,
  onLeftAdd,
  onRightAdd,
  onDelete,
  onUpdate
}: StageColumnProps) => {
  const hasPermission =
    roleWorkflow.isCreator ||
    roleWorkflow.isProcessManagement ||
    stage.roleWorkflowByUser?.isStageManagement;

  const itemsPadding = { width: "100%", padding: "11px 12px" };

  const menuItems: IMenuItem[] = [
    {
      value: "edit",
      label: (
        <Box sx={itemsPadding} onClick={onUpdate}>
          Chỉnh sửa giai đoạn
        </Box>
      )
    },
    // {
    //   value: "edit-guideline",
    //   label: (
    //     <Box sx={itemsPadding}>
    //       Hướng dẫn hoàn thành các nhiệm vụ trong giai đoạn
    //     </Box>
    //   )
    // },
    // {
    //   value: "edit-task",
    //   label: (
    //     <Box sx={itemsPadding}>
    //       Tuỳ chỉnh các công việc tạo sẵn trong giai đoạn
    //     </Box>
    //   )
    // },
    // {
    //   value: "edit-drag",
    //   label: (
    //     <Box sx={itemsPadding} onClick={props.onManageDrag}>
    //       Quản lý tuỳ chọn kéo ngược
    //     </Box>
    //   )
    // },
    // {
    //   value: "advanced",
    //   label: (
    //     <Box sx={itemsPadding} onClick={props.onAdvancedOptions}>
    //       Tuỳ chọn nâng cao
    //     </Box>
    //   )
    // },
    {
      value: "delete",
      label: (
        <Box sx={{ ...itemsPadding, color: "#ff4e4e" }} onClick={onDelete}>
          Xoá giai đoạn
        </Box>
      )
    },
    {
      value: "divider",
      label: (
        <Divider
          sx={{
            height: "1px",
            width: "90%",
            marginInline: 1
          }}
        />
      )
    },
    {
      value: "left_add",
      label: (
        <Box sx={itemsPadding} onClick={onLeftAdd}>
          Thêm 1 giai đoạn bên trái
        </Box>
      )
    },
    {
      value: "right_add",
      label: (
        <Box sx={itemsPadding} onClick={onRightAdd}>
          Thêm 1 giai đoạn bên phải
        </Box>
      )
    }
  ];

  return (
    <div
      className={`${stage.type === EStageType.Complete ? "bg-green-400" : stage.type === EStageType.Cancel ? "bg-red-400" : "bg-gray-100"}  shrink-0 grow-0 shadow${dragItem ? " rotate-3" : ""}`}
      style={{
        marginBottom: "8px",
        width: "256px"
      }}
    >
      <div
        className={`border-gray-200 ${stage.type === EStageType.Complete ? "bg-green-400" : stage.type === EStageType.Cancel ? "bg-red-400" : "bg-gray-100"}`}
        style={{
          padding: "8px 0"
        }}
      >
        <Box
          className=" flex flex-row justify-between align-center text-wrap"
          sx={{
            padding: "0 12px",
            "& #basic-button": {
              "& .menu-button": {
                padding: 0
              }
            }
          }}
        >
          <Typography
            className="truncate font-bold prevent-select flex-shrink"
            fontSize={16}
            padding={"8px 0"}
          >
            {name}
          </Typography>
          {isShowMenu && hasPermission && (
            <div className="flex-0">
              <BasicMenu
                key={name}
                onChange={() => {}}
                label={<ArrowDropDownIcon sx={{ padding: 0, fontSize: 24 }} />}
                options={menuItems}
                padding={0}
              />
            </div>
          )}
          {!isShowMenu && <Box height={24} />}
        </Box>
        <Box sx={{ padding: "0 12px" }}>
          <BorderLinearProgress
            variant="determinate"
            value={0}
            height={4}
            barcolor="#308fe8"
            background="#dbdbdb"
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            color={"#8e8e8e"}
            fontSize={10}
          >
            <Typography
              className={` ${stage.type !== EStageType.Step && "text-white"} prevent-select`}
              fontSize={12}
              marginTop={"4px"}
            >
              {stage.taskCards.length} nhiệm vụ
            </Typography>
            {/* <Box sx={{ margin: "0 2px" }}> {"-"} </Box>
        <p>0 Q.HAN</p> */}
          </Box>
        </Box>
      </div>
      {children}
    </div>
  );
};
export default StageColumn;
