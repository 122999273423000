import { styled } from "@mui/material";
import variableStyles from "../../../../theme/variable-styles";
import { responsiveFontSizes } from "../../../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  "@media (max-width: 868px)": {
    margin: "12px",
    svg: { width: "24px", height: "24px" },
    "& .docs-header": {
      marginTop: "25%",
      ...responsiveFontSizes({
        fontsize: {
          xs: 16,
          sm: 16,
          md: 18,
          lg: 20,
          xl: 20
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .docs-title": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 12,
          sm: 12,
          md: 14,
          lg: 14,
          xl: 14
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    }
  },
  "& .attachmentFiles-container": {
    //     textOverflow: "ellipsis",
    //     whiteSpace: "nowrap",
    //     overflow: "hidden"
  }
}));
