import { styled } from "@mui/material";
import { responsiveFontSizes } from "../../../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  // margin: "12px 20px",
  "& .header-page": {
    color: "black",
    flexGrow: 1,
    display: "flex",
    button: {
      marginRight: "24px",
      color: "GrayText"
    }
  },
  "& .admin-action": {
    button: { marginLeft: "12px", borderRadius: "4px" }
  },

  "& .admin-container": {
    margin: "14px 20px",
    marginTop: "12px",
    display: "flex",
    justifyContent: "space-between",
    button: {
      marginLeft: "28px",
      borderRadius: "4px",
      padding: "8px 0"
    }
  },
  "& .main-container": {
    marginTop: "12px",
    margin: "12px 20px"
  },
  "@media (max-width: 868px)": {
    svg: { fontSize: "18px" },
    "& .name-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 16,
          sm: 16,
          md: 18,
          lg: 18,
          xl: 18
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    p: {
      ...responsiveFontSizes({
        fontsize: {
          xs: 12,
          sm: 12,
          md: 14,
          lg: 14,
          xl: 14
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    }
  }
}));
