import { Box, styled, Typography } from "@mui/material";
import Avatar from "../../../components/Avatar";
import CircleIcon from "@mui/icons-material/Circle";
import { responsiveFontSizes } from "../../../utils/responsive";

type Props = {
  name: string | null;
  tag: string;
  position: string;
  avatar?: string | null;
};

const AccountLayoutWrapper = styled("div")({
  "@media (max-width: 868px)": {
    "& .name-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 14,
          sm: 14,
          md: 16,
          lg: 16,
          xl: 16
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .username-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 12,
          sm: 12,
          md: 14,
          lg: 14,
          xl: 14
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    }
  }
});

const AccountLayout = (props: Props) => {
  return (
    <AccountLayoutWrapper>
      <Box sx={{ display: "flex" }}>
        <Avatar
          src={props?.avatar}
          alt="Avatar-Image"
          width={"34px"}
          height={"34px"}
        />
        <div style={{ marginLeft: "12px" }}>
          <Typography className="name-text" variant="subtitle2">
            {props.name}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography className="username-text" variant="caption">
              {props.tag}
            </Typography>
            <CircleIcon sx={{ width: "2px", margin: "0 8px" }} />
            <Typography className="username-text" variant="caption">
              {props.position}
            </Typography>
          </Box>
        </div>
      </Box>
    </AccountLayoutWrapper>
  );
};

export default AccountLayout;
