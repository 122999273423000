import { Box, Button, Divider } from "@mui/material";
import React, { useMemo, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BasicMenu, { IMenuItem } from "../../components/Menu/BasicMenu";
import CreateTaskModal from "./CreateTaskModal/create_task_modal";
import { IWorkflow } from "../../models/workflow";
import CreateWorkflowDialog from "../WorkflowManagement/components/CreateWorkflowDialog/create_workflow_dialog";
import { IRoleBoard } from "../../models/Stage";
import { EStageType } from "../../models/common/models.enum";
import CopyWorkflowModal from "./CopyWorkflowModal/copy_workflow_modal";

interface Props {
  workflow: IWorkflow;
  role: IRoleBoard;
  hasStepStage: boolean;
  onCloseWorkflow: (id: string) => void;
  onCreateStage: () => void;
  onCreateTaskSuccess: () => void;
  onUpdateWorkflowSuccess: () => void;
  onCopyWorkflowSuccess?: () => void;
}

const ActionProject = ({
  workflow,
  role,
  hasStepStage,
  onCloseWorkflow,
  onCreateStage,
  onCreateTaskSuccess,
  onUpdateWorkflowSuccess,
  onCopyWorkflowSuccess
}: Props) => {
  const [openCreateTask, setOpenCreateTask] = useState<boolean>(false);
  const [openUpdateWorkflow, setOpenUpdateWorkflow] = useState<boolean>(false);
  const [openCopyWorkflow, setOpenCopyWorkflow] = useState<boolean>(false);

  return (
    <Box
      sx={{
        position: "absolute",
        top: { xs: 60, md: 0 },
        right: 0
      }}
    >
      <Box
        className="content-header"
        sx={{ display: "flex", margin: { xs: "0 8px", md: "10px  16px 0 0" } }}
      >
        <Box
          className="header-action"
          sx={{
            display: "flex",
            button: {
              marginLeft: "8px"
            },
            "& #basic-button": {
              "& .menu-button": { padding: 0 }
            }
          }}
        >
          <Button
            size="small"
            variant="outlined"
            color="info"
            sx={{ fontSize: 13, fontWeight: 700 }}
          >
            {workflow.totalTask}/{workflow.totalTask} nhiệm vụ
          </Button>

          {(role.isProcessManagement ||
            role.isCreator ||
            role.isAssignTask) && (
            <Button
              size="small"
              variant="contained"
              color="info"
              sx={{ fontSize: 13, fontWeight: 700 }}
              onClick={_ =>
                hasStepStage ? setOpenCreateTask(true) : onCreateStage()
              }
            >
              Tạo nhiệm vụ
            </Button>
          )}
          {(role.isProcessManagement || role.isCreator) && (
            <BasicMenu
              onChange={() => {}}
              label={
                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  sx={{ minWidth: "unset" }}
                >
                  <MoreHorizIcon sx={{ fontSize: 24 }} />
                </Button>
              }
              padding={0}
              options={[
                {
                  value: "edit",
                  label: (
                    <Box
                      sx={{ width: "100%", padding: "11px 12px" }}
                      onClick={() => onCreateStage()}
                    >
                      Thêm giai đoạn
                    </Box>
                  )
                },
                {
                  value: "update",
                  label: (
                    <Box
                      sx={{ width: "100%", padding: "11px 12px" }}
                      onClick={() => setOpenUpdateWorkflow(true)}
                    >
                      Cập nhật luồng công việc
                    </Box>
                  )
                },
                {
                  value: "clone",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px"
                      }}
                      onClick={() => setOpenCopyWorkflow(true)}
                    >
                      Nhân bản luồng công việc
                    </Box>
                  )
                },
                {
                  value: "divider",
                  label: (
                    <Divider
                      sx={{
                        height: "1px",
                        width: "90%",
                        marginInline: 1,
                        minHeight: "1px"
                      }}
                    />
                  )
                },

                {
                  value: "delete",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px",
                        color: "#ff4e4e"
                      }}
                      onClick={() => onCloseWorkflow(workflow.id)}
                    >
                      Đóng luồng công việc
                    </Box>
                  )
                }
              ]}
            />
          )}
          <CreateTaskModal
            open={openCreateTask}
            workflowId={workflow.id}
            onCreateSuccess={onCreateTaskSuccess}
            handleClose={() => setOpenCreateTask(false)}
          />
          <CreateWorkflowDialog
            open={openUpdateWorkflow}
            workflowId={workflow.id}
            handleClose={() => setOpenUpdateWorkflow(false)}
            handleSuccess={onUpdateWorkflowSuccess}
          />
          <CopyWorkflowModal
            open={openCopyWorkflow}
            workflowId={workflow.id}
            handleClose={() => setOpenCopyWorkflow(false)}
            handleSuccess={
              onCopyWorkflowSuccess ? onCopyWorkflowSuccess : () => {}
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ActionProject;
