import { styled } from "@mui/material/styles";
import variableStyles from "../../theme/variable-styles";
import { responsiveFontSizes } from "../../utils/responsive";

export const Wrapper = styled("div")(() => ({
  // margin: "24px",
  "& .content-container": {
    width: "70%",
    margin: "32px auto",
    "& .header": {
      display: "flex"
    },

    "& .account-identity": {
      display: "flex",
      marginTop: "6vh"
    },

    "& .account-info": {
      display: "flex",
      margin: "32px 12px",
      "& .info-row": {
        marginBottom: "24px",
        "& .row-infomation": {
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
          svg: {
            color: "#AAAAB2",
            fontSize: "20px"
          },
          p: {
            fontSize: "14px",
            marginLeft: "12px"
          }
        }
      }
    }
  },
  "@media (max-width: 868px)": {
    "& .account-info": {
      display: "flex",
      flexDirection: "column"
    },
    "& .body1-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 16,
          sm: 16,
          md: 18,
          lg: 18,
          xl: 18
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .subtitle1-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 16,
          sm: 16,
          md: 18,
          lg: 18,
          xl: 18
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    },
    "& .content-container": {
      margin: "12px"
    },
    "& .header-text": { display: "none" },
    "& .button-text": {
      ...responsiveFontSizes({
        fontsize: {
          xs: 14,
          sm: 14,
          md: 16,
          lg: 16,
          xl: 16
        },
        lineheight: {
          xs: 22.59,
          sm: 22.59,
          md: 22.59,
          lg: 22.59,
          xl: 22.59
        }
      })
    }
  }
}));
