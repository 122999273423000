import {
  Button,
  Box,
  Typography,
  Breadcrumbs,
  Card,
  TextField,
  Divider
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Wrapper } from "./styles";
import JobDetailProgress from "../JobDetailProgress/JobDetailProgress";
import ContentLayout from "../ContentLayout/ContentLayout";
import variableStyles from "../../../../../theme/variable-styles";
import { Avatar, Modal } from "../../../../../components";
import { createSearchParams, useSearchParams } from "react-router-dom";
import StageProgress from "../StageProgress";
import TaskService from "../../../../../services/api/task.service";
import { IStageHistory, ITask } from "../../../../../models/Task";
import { ROUTE_PATH } from "../../../../../common/constants/app.constant";
import useNavigateSearch from "../../../../../components/NavigateSearch";
import { toggleMessage } from "../../../../../components/Toast/Toast";
import useAxios from "../../../../../components/UseAxios/useAxios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import CreateTaskModal from "../../../CreateTaskModal/create_task_modal";
import {
  EStageType,
  ETaskStatus
} from "../../../../../models/common/models.enum";
import CommentTask from "../CommentTask/CommentTask";
import { Helmet } from "react-helmet";
import { fTime } from "../../../../../utils/formatTime";
import EditTaskDescriptionModal from "../../../EditTaskDescriptionModal/EditTaskDescriptionModal";
import EditTaskAttachmentModal from "../../../EditTaskAttachmentModal/EditTaskAttachmentModal";
import FileImage from "../CommentTask/components/FileImage";
import ChildrenTask from "../ChildrenTask/ChildrenTask";

dayjs.extend(duration);

function RowIconLayout(props: {
  icon: React.ReactElement;
  content: React.ReactElement;
}) {
  return (
    <Box display="flex" margin="6px 0">
      {props.icon}
      <Box alignContent={"center"} style={{ marginLeft: "8px" }}>
        {props.content}
      </Box>
    </Box>
  );
}

interface IProps {
  id?: string;
  onJobUpdated?: () => void;
}

const TaskDetail = ({ id, onJobUpdated: onStatusUpdated }: IProps) => {
  const navigateSearch = useNavigateSearch();
  const [searchParams] = useSearchParams();
  const [forceUpdateSubTasks, setForce] = useState("");
  const deleteTaskAxios = useAxios<string>({ loading: "OnRequest" });
  const getTaskInfo = useAxios<ITask>({ loading: "OnRequest" });
  const markAsFailedAxios = useAxios<string>({ loading: "OnRequest" });
  const nextStageAxios = useAxios<string>({ loading: "OnRequest" });

  const [remainingTime, setRemainingTime] = useState<string | null>(null);
  const [openFailureDialog, setOpenFailureDialog] = useState(false);
  const [openNextStageDialog, setOpenNextStageDialog] = useState(false);
  const [failureReason, setFailureReason] = useState("");
  const [openCreateSubTask, setOpenCreateSubTask] = useState(false);
  const [openUpdateDescription, setOpenUpdateDescription] = useState(false);
  const [openUpdateAttachment, setOpenUpdateAttachment] = useState(false);
  const taskId = useMemo(() => id ?? searchParams.get("id"), [searchParams]);
  const isHasPermission = useMemo(() => {
    if (!getTaskInfo.data) return false;
    return (
      getTaskInfo.data.roleBoardByUser.isCreator ||
      getTaskInfo.data.roleBoardByUser.isProcessManagement
    );
  }, [getTaskInfo.data]);

  useEffect(() => {
    getTask(taskId);
  }, [taskId]);

  // Filter stages based on the current stage type
  const filteredStages = useMemo(() => {
    if (!getTaskInfo.data) return [];
    const currentStageIndex = getTaskInfo.data.stages.findIndex(
      e => e.name === getTaskInfo.data!.info.currentStage
    );

    return getTaskInfo.data.stages.reduce((acc, stage, index) => {
      const currentStage = getTaskInfo.data!.stages[currentStageIndex];
      const currentStageType = currentStage?.type;

      if (currentStageType !== EStageType.Step) {
        // If current stage is not a step, include all steps and the current stage
        if (stage.type === EStageType.Step || stage.id === currentStage?.id) {
          acc.push(stage);
        }
      } else {
        // If current stage is a step, include all steps and one non-step stage
        if (stage.type === EStageType.Step) {
          acc.push(stage);
        } else if (acc.every(s => s.type === EStageType.Step)) {
          // Add one non-step stage if we haven't added any yet
          acc.push(stage);
        }
      }

      return acc;
    }, [] as IStageHistory[]);
  }, [getTaskInfo.data]);

  const sla = useMemo(() => {
    const stageExpectedTime = filteredStages.find(
      e => e.id === getTaskInfo.data?.taskCard.workflowId
    )?.histories?.expectedTime;

    if (stageExpectedTime) {
      return fTime(stageExpectedTime);
    }
    return null;
  }, [filteredStages]);

  useEffect(() => {
    if (getTaskInfo.data && getTaskInfo.data.taskCard.deadLine) {
      const updateRemainingTime = () => {
        const now = dayjs();
        const deadline = dayjs(getTaskInfo.data!.taskCard.deadLine);
        if (deadline.isBefore(now)) {
          setRemainingTime(null);
        } else {
          const diff = deadline.diff(now);
          const duration = dayjs.duration(diff);
          const totalHours = Math.floor(duration.asHours());
          const minutes = duration.minutes().toString().padStart(2, "0");
          const seconds = duration.seconds().toString().padStart(2, "0");
          setRemainingTime(`${totalHours}:${minutes}:${seconds}`);
        }
      };

      updateRemainingTime();
      const timer = setInterval(updateRemainingTime, 1000);

      return () => clearInterval(timer);
    }
  }, [getTaskInfo.data]);

  useEffect(() => {
    if (getTaskInfo.error)
      toggleMessage({
        type: "error",
        message: getTaskInfo.error.message ?? ""
      });
  }, [getTaskInfo.error]);

  useEffect(() => {
    if (deleteTaskAxios.isSuccess)
      navigateSearch(ROUTE_PATH.PROJECTS, {
        id: getTaskInfo.data!.taskCard.boardId as string
      });
  }, [deleteTaskAxios.isSuccess]);

  useEffect(() => {
    if (markAsFailedAxios.isSuccess) {
      onStatusUpdated?.();
      toggleMessage({
        type: "success",
        message:
          markAsFailedAxios.message ?? "Nhiệm vụ được đánh dấu là thất bại"
      });
      handleCloseFailureDialog();
      getTask(taskId);
      navigateSearch(ROUTE_PATH.PROJECTS, {
        id: getTaskInfo.data!.taskCard.boardId as string
      }); // Back to board
    }
    if (markAsFailedAxios.error) {
      toggleMessage({
        type: "error",
        message: markAsFailedAxios.error.message ?? "Xảy ra lỗi ngoài ý muốn"
      });
    }
  }, [markAsFailedAxios.isSuccess, markAsFailedAxios.error]);

  useEffect(() => {
    if (nextStageAxios.isSuccess) {
      onStatusUpdated?.();
      toggleMessage({
        type: "success",
        message:
          nextStageAxios.message ??
          "Nhiệm vụ đã được chuyển sang giai đoạn tiếp theo"
      });
      handleCloseNextStageDialog();
      getTask(taskId);
    }
    if (nextStageAxios.error) {
      toggleMessage({
        type: "error",
        message: nextStageAxios.error.message ?? "Nhiệm vụ chuyển tiếp thất bại"
      });
    }
  }, [nextStageAxios.isSuccess, nextStageAxios.error]);

  const getTask = async (id: string | null) => {
    if (id) getTaskInfo.request(TaskService.getSingle(id));
  };

  const deleteTask = async () => {
    if (getTaskInfo.data!)
      deleteTaskAxios.request(
        TaskService.deleteTask(getTaskInfo.data!.info.id)
      );
  };

  const onUpdateTrackersSuccess = () => {
    onStatusUpdated?.();
    getTask(taskId);
  };

  const onUpdateAttachmentSuccess = () => {
    onStatusUpdated?.();
    getTask(taskId);
  };

  const onUpdateDescriptionSuccess = () => {
    onStatusUpdated?.();
    getTask(taskId);
  };

  const handleOpenFailureDialog = () => {
    setOpenFailureDialog(true);
  };

  const handleCloseFailureDialog = () => {
    setOpenFailureDialog(false);
    setFailureReason("");
  };

  const handleOpenNextStageDialog = () => {
    setOpenNextStageDialog(true);
  };

  const handleCloseNextStageDialog = () => {
    setOpenNextStageDialog(false);
  };

  const handleMarkAsFailed = () => {
    if (getTaskInfo.data && failureReason.trim()) {
      markAsFailedAxios.request(
        TaskService.markAsFailed(getTaskInfo.data.taskCard.id, failureReason)
      );
    } else {
      toggleMessage({
        type: "error",
        message: "Vui lòng nhập lý do thất bại"
      });
    }
  };

  const handleNextStage = () => {
    if (getTaskInfo.data) {
      nextStageAxios.request(TaskService.nextStage(getTaskInfo.data.info.id));
    }
  };

  return (
    <Wrapper
      active={
        (getTaskInfo.data === undefined && getTaskInfo.isLoading) ||
        deleteTaskAxios.isLoading ||
        markAsFailedAxios.isLoading ||
        nextStageAxios.isLoading
      }
      spinner
    >
      {getTaskInfo.data && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getTaskInfo.data.taskCard.title ?? "Nhiệm vụ"}</title>
        </Helmet>
      )}
      {getTaskInfo.data === undefined && getTaskInfo.isLoading && (
        <Box sx={{ height: "500px", width: "100%" }}></Box>
      )}

      {getTaskInfo.data && (
        <Box className="content-container">
          <Box className="content-header">
            {!id && (
              <ArrowBackIcon
                onClick={() =>
                  navigateSearch(ROUTE_PATH.PROJECTS, {
                    id: getTaskInfo.data!.taskCard.boardId as string
                  })
                }
                sx={{ color: variableStyles.greyA100 }}
              />
            )}
            <Box className="header-title">
              <Typography color="black" fontSize={18} fontWeight={700}>
                {getTaskInfo.data.taskCard.title}
              </Typography>
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                sx={{ fontSize: 16 }}
              >
                <Typography
                  variant="overline"
                  sx={{
                    fontSize: "11px",
                    fontWeight: 600,
                    letterSpacing: "unset",
                    color: "#a8a8a8",
                    "&:hover": {
                      textDecoration: "underline #a8a8a8",
                      cursor: "pointer"
                    }
                  }}
                  onClick={() =>
                    navigateSearch(ROUTE_PATH.PROJECTS, {
                      id: getTaskInfo.data!.taskCard.boardId
                    })
                  }
                >
                  {getTaskInfo.data.info.boardName}
                </Typography>
                ,
                <Typography
                  variant="overline"
                  fontSize={"11px"}
                  color={"#a8a8a8"}
                  fontWeight={600}
                  letterSpacing={"unset"}
                >
                  {getTaskInfo.data.info.currentStage}
                </Typography>
              </Breadcrumbs>
            </Box>

            {![ETaskStatus.Failed, ETaskStatus.Complete].includes(
              getTaskInfo.data.taskCard.status ?? ETaskStatus.Inprogress
            ) && (
              <Button
                variant="contained"
                size="small"
                sx={{
                  borderRadius: 2,
                  bgcolor: "#53b727",
                  height: 32,
                  fontSize: 12,
                  boxShadow: "unset"
                }}
                startIcon={<PlayArrowRoundedIcon />}
                onClick={
                  !nextStageAxios.isLoading
                    ? () => handleOpenNextStageDialog()
                    : undefined
                }
              >
                Chuyển tiếp
              </Button>
            )}

            {getTaskInfo.data.taskCard.status !== ETaskStatus.Failed && (
              <Button
                size="small"
                variant="contained"
                sx={{
                  borderRadius: 2,
                  bgcolor: "#f8ecec",
                  color: "#be495d",
                  margin: "0 10px",
                  height: 32,
                  fontSize: 12,
                  boxShadow: "unset"
                }}
                onClick={
                  !markAsFailedAxios.isLoading
                    ? () => handleOpenFailureDialog()
                    : undefined
                }
              >
                Đánh dấu thất bại
              </Button>
            )}

            {/* <BasicMenu
              onChange={() => {}}
              label={
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    minWidth: "unset",
                    color: "#878787",
                    bgcolor: "#f3f3f3",
                    height: 32,
                    width: 32,
                    boxShadow: "unset"
                  }}
                >
                  <KeyboardArrowDownRoundedIcon
                    sx={{ height: 18, width: 18 }}
                  />
                </Button>
              }
              padding={0}
              options={[
                {
                  value: "delete",
                  label: (
                    <Box
                      sx={{
                        width: "100%",
                        padding: "11px 12px",
                        color: "#ff4e4e"
                      }}
                      onClick={() => deleteTask()}
                    >
                      Xoá
                    </Box>
                  )
                }
              ]}
            /> */}
          </Box>
          <Divider sx={{ margin: "16px 0", borderColor: "#f0f0f0" }}></Divider>

          {getTaskInfo.data.taskCard.personAssigned && (
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography fontSize={18} color="black" fontWeight={400}>
                {getTaskInfo.data.taskCard.title}
              </Typography>
              <Box display="flex" alignItems={"center"}>
                <Box flexGrow={3}>
                  <Avatar
                    src={getTaskInfo.data.taskCard.personAssigned.avatar}
                    alt="Avatar-Image"
                    width={20}
                    height={20}
                  />
                </Box>
                <Typography
                  fontSize={12}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  maxWidth={"100px"}
                  paddingLeft={1}
                >
                  {getTaskInfo.data.taskCard.personAssigned.fullName}
                </Typography>
              </Box>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box>
              <div className="content-row">
                <RowIconLayout
                  content={
                    <div
                      style={{ color: "#858585", lineHeight: 1, fontSize: 12 }}
                    >
                      {getTaskInfo.data.taskCard.description ? (
                        <p>
                          {(() => {
                            const parser = new DOMParser();
                            const doc = parser.parseFromString(
                              getTaskInfo.data.taskCard.description,
                              "text/html"
                            );
                            const firstTextNode =
                              doc.body.querySelector("p, div, span");
                            if (firstTextNode) {
                              const text = firstTextNode.textContent || "";
                              return (
                                text.split(" ").slice(0, 20).join(" ") +
                                (text.split(" ").length > 20 ? "..." : "")
                              );
                            }
                            return "Không có mô tả";
                          })()}
                        </p>
                      ) : (
                        "Không có mô tả"
                      )}
                    </div>
                  }
                  icon={
                    <BookmarkRoundedIcon
                      sx={{ color: variableStyles.greyA100, fontSize: 16 }}
                    />
                  }
                />
              </div>
              <div className="content-row">
                <RowIconLayout
                  content={
                    <Box display="flex">
                      <Typography fontSize={12} color={"#858585"}>
                        Thời hạn trong giai đoạn:
                      </Typography>
                      <Typography sx={{ margin: "0 4px", fontSize: 12 }}>
                        {getTaskInfo.data.taskCard.deadLine
                          ? dayjs(getTaskInfo.data.taskCard.deadLine).format(
                              "HH:mm DD/MM/YYYY"
                            )
                          : "Không thời hạn"}
                      </Typography>
                      <Typography color={"#858585"} fontSize={12}>
                        - SLA:
                      </Typography>
                      <Typography sx={{ margin: "0 4px" }} fontSize={12}>
                        {sla
                          ? `${sla.hours ? `${sla.hours}h` : ""}${sla.minutes ? ` ${sla.minutes}m` : ""}`
                          : "0m"}
                      </Typography>
                      {remainingTime && (
                        <Typography color={"#858585"} fontSize={12}>
                          Còn lại:
                          <Box
                            component="span"
                            fontWeight="fontWeightMedium"
                            fontSize={14}
                            color={variableStyles.GreenPrimaryColor400}
                          >
                            {" "}
                            {remainingTime}
                          </Box>
                        </Typography>
                      )}
                    </Box>
                  }
                  icon={
                    <ErrorRoundedIcon
                      sx={{ color: variableStyles.greyA100, fontSize: 16 }}
                    />
                  }
                />
              </div>
              {/* <div className="content-row">
                <RowIconLayout
                  content={<Tag name="backend" />}
                  icon={
                    <SellRoundedIcon
                      sx={{ color: variableStyles.greyA100, width: "16px" }}
                    />
                  }
                />
              </div> */}
            </Box>
            {getTaskInfo.data.taskCard.deadLine && (
              <Box display="flex" flexDirection="column" maxWidth={120}>
                <Divider
                  sx={{ borderStyle: "dashed", margin: "10px 0" }}
                ></Divider>

                <Typography fontSize={12} color={"#858585"} textAlign={"end"}>
                  DEADLINE:
                  <Box
                    component="span"
                    fontWeight="fontWeightRegular"
                    fontSize={12}
                    color={variableStyles.colorBlack}
                  >
                    {dayjs(getTaskInfo.data.taskCard.deadLine).format(
                      " HH:mm "
                    )}
                  </Box>
                  <Box
                    component="span"
                    fontSize={12}
                    fontWeight="fontWeightRegular"
                    color={"#E0868C"}
                  >
                    {dayjs(getTaskInfo.data.taskCard.deadLine)
                      .locale("vi")
                      .format("dddd, DD/MM/YYYY")}
                  </Box>
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              margin: "16px 0",
              width: "100%"
            }}
          >
            <StageProgress
              currentStage={getTaskInfo.data.info.currentStage}
              stages={filteredStages}
            />
          </Box>
          <ContentLayout
            title="Mô tả"
            action={
              isHasPermission ? (
                <Box>
                  <Button
                    sx={{ color: variableStyles.Info500 }}
                    onClick={() => setOpenUpdateAttachment(true)}
                  >
                    <Typography fontSize={12} lineHeight={1.5}>
                      Tải lên tài liệu
                    </Typography>
                  </Button>
                  <Button
                    variant="text"
                    sx={{
                      fontSize: 12,
                      lineHeight: 1.5,
                      marginLeft: "16px",
                      color: variableStyles.Info500
                    }}
                    onClick={() => setOpenUpdateDescription(true)}
                  >
                    <Typography fontSize={12} lineHeight={1.5}>
                      Chỉnh sửa
                    </Typography>
                  </Button>
                </Box>
              ) : null
            }
            children={
              <Card
                sx={{
                  marginTop: "10px",
                  borderRadius: 1.5,
                  background: "#f9f9f9",
                  boxShadow: "unset",
                  padding: "8px 16px 8px"
                }}
              >
                <Box sx={{ paddingBottom: "8px" }}>
                  {getTaskInfo.data.taskCard.description ? (
                    <div
                      // style={{ color: "#858585" }}
                      dangerouslySetInnerHTML={{
                        __html: getTaskInfo.data.taskCard.description
                      }}
                    />
                  ) : (
                    <div>Không có mô tả</div>
                  )}
                  {getTaskInfo.data.taskCard.attachmentFiles &&
                    getTaskInfo.data.taskCard.attachmentFiles.length > 0 && (
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        color={"#858585"}
                        fontSize={12}
                      >
                        Tài liệu đính kèm:
                        {getTaskInfo.data.taskCard.attachmentFiles.map(file => (
                          <Box
                            key={file.id ?? file.path}
                            display={"flex"}
                            padding={0.5}
                            flexDirection={"row"}
                            gap={1}
                            sx={{ cursor: "pointer" }}
                          >
                            <FileImage type={file.extension.toLowerCase()} />
                            <Typography
                              color="#3091F3"
                              variant="subtitle2"
                              onClick={() => {
                                window.open(
                                  ROUTE_PATH.RESOURCE +
                                    `?${createSearchParams({ type: file.extension, name: file.fileName, path: file.path })}`,
                                  "_blank"
                                );
                              }}
                            >
                              {file.fileName}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                </Box>
              </Card>
            }
          />

          {/* <ContentLayout
            title={"Trường tuỳ chỉnh"}
            action={
              <Button sx={{ color: variableStyles.Info500 }}>
                <Typography variant="caption">Xem tất cả</Typography>
              </Button>
            }
          /> */}

          <ContentLayout
            title={"Danh sách công việc"}
            action={
              isHasPermission ? (
                <Button
                  sx={{ color: variableStyles.Info500 }}
                  onClick={() => setOpenCreateSubTask(true)}
                >
                  <Typography fontSize={12}>Thêm công việc</Typography>{" "}
                </Button>
              ) : null
            }
            children={
              <ChildrenTask
                TaskCardId={getTaskInfo.data.taskCard.id}
                BoardId={getTaskInfo.data.taskCard.boardId}
                isHasPermission={isHasPermission}
                stages={filteredStages}
                force={forceUpdateSubTasks}
              />
            }
          />

          <CommentTask taskId={`${getTaskInfo.data.taskCard.id}`} />
        </Box>
      )}
      {/* Right */}
      {getTaskInfo.data && (
        <Box className="container-progress">
          <JobDetailProgress
            key={getTaskInfo.data.taskCard.id}
            canUpdateTrackers={isHasPermission}
            task={getTaskInfo.data}
            stagesHistorys={filteredStages}
            onUpdateTrackersSuccess={onUpdateTrackersSuccess}
          />
        </Box>
      )}
      {getTaskInfo.data && (
        <CreateTaskModal
          open={openCreateSubTask}
          taskId={getTaskInfo.data.info.id}
          isUpdate={false}
          hasDocument={false}
          workflowId={getTaskInfo.data.taskCard.boardId}
          onCreateSuccess={newTaskcard => {
            setOpenCreateSubTask(false);
            setForce((newTaskcard?.id as string) ?? "");
          }}
          handleClose={() => setOpenCreateSubTask(false)}
        />
      )}

      <Modal
        title={"Đánh dấu thất bại"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={openFailureDialog}
        onSubmit={handleMarkAsFailed}
        disabledSubmit={markAsFailedAxios.isLoading}
        onClose={handleCloseFailureDialog}
        width="500px"
      >
        <TextField
          margin="dense"
          id="failureReason"
          required
          type="text"
          fullWidth
          variant="outlined"
          placeholder="Nhập lý do thất bại"
          value={failureReason}
          onChange={e => setFailureReason(e.target.value)}
        />
      </Modal>
      <Modal
        title={"Chuyển tiếp công việc"}
        textSubmit={"Xác nhận"}
        textClose="Huỷ bỏ"
        open={openNextStageDialog}
        onSubmit={handleNextStage}
        disabledSubmit={nextStageAxios.isLoading}
        onClose={handleCloseNextStageDialog}
        width="500px"
      >
        {"Xác nhận chuyển tiếp công việc sang giai đoạn tiếp theo?"}
      </Modal>
      {getTaskInfo.data && (
        <EditTaskDescriptionModal
          open={openUpdateDescription}
          onClose={() => setOpenUpdateDescription(false)}
          onUpdateSuccess={onUpdateDescriptionSuccess}
          task={getTaskInfo.data.taskCard}
        />
      )}
      {getTaskInfo.data && (
        <EditTaskAttachmentModal
          open={openUpdateAttachment}
          onClose={() => setOpenUpdateAttachment(false)}
          onUpdateSuccess={onUpdateAttachmentSuccess}
          task={getTaskInfo.data.taskCard}
        />
      )}
    </Wrapper>
  );
};

export default TaskDetail;
