import { WorkflowManagementWrapper } from "./styles";
import { Roles } from "../../models/common/models.enum";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Box, Button } from "@mui/material";
import { Add } from "@mui/icons-material";

import { useCallback, useEffect, useState } from "react";
import CreateWorkflowDialog from "./components/CreateWorkflowDialog/create_workflow_dialog";
import WorkflowBoard from "./components/WorkflowBoard/WorkflowBoard";
import { Helmet } from "react-helmet";
import useNavigateSearch from "../../components/NavigateSearch";
import { useSearchParams } from "react-router-dom";

export interface ITabItem {
  href: string;
  title: string;
  code?: string;
}

enum TabCode {
  AVAILABILITY = "availability",
  CLOSED = "closed",
  MINE = "mine"
}

const tabItems = (role?: Roles): ITabItem[] => {
  const AVAILABIILITY: ITabItem = {
    href: ROUTE_PATH.WORKFLOWS_MANAGEMENT,
    title: "Đang khả dụng",
    code: TabCode.AVAILABILITY
  };
  const CLOSED: ITabItem = {
    href: ROUTE_PATH.WORKFLOWS_MANAGEMENT,
    title: "Đã đóng",
    code: TabCode.CLOSED
  };
  const MINE: ITabItem = {
    href: ROUTE_PATH.WORKFLOWS_MANAGEMENT,
    title: "Tạo bởi tôi",
    code: TabCode.MINE
  };
  return [AVAILABIILITY, CLOSED, MINE];
};

const WorkflowManagement = () => {
  const tabItemsData = tabItems();

  const [tabIndex, setTabIndex] = useState(5);
  const [openWorkflowForm, setOpenWorkflowForm] = useState(false);
  const [key, setKey] = useState(0);

  const navigateSearch = useNavigateSearch();
  const [searchParams] = useSearchParams();

  const reload = useCallback(() => {
    setKey(prevKey => prevKey + 1);
  }, []);

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      setTabIndex(tabItemsData.findIndex(item => item.code === tab));
    }
    if (!tab) {
      setTabIndex(0);
    }
  }, [searchParams]);

  const handleOpenCreateWorkflow = () => {
    setOpenWorkflowForm(true);
  };

  const handleCloseCreateWorkflow = () => {
    setOpenWorkflowForm(false);
  };

  const handleCreateWorkflowSuccess = () => {
    reload();
    setTabIndex(2);
  };

  return (
    <WorkflowManagementWrapper spinner>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Quản lí quy trình"}</title>
      </Helmet>
      <div className="workflow-header flex">
        <div className="workflow-icon">
          <WidgetsIcon sx={{ color: "#AAAAAA", width: 18, height: 18 }} />
        </div>

        <Box className="workflow-header-info" sx={{ flexGrow: 1 }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            className="workflow-title"
            sx={{ flexGrow: 1 }}
          >
            <span className="name">Quy trình</span>
            <Box display={"flex"} flexDirection={"row"} sx={{ height: 40 }}>
              <Button
                component="label"
                size="small"
                variant="contained"
                sx={{
                  height: 34,
                  background: "#267CDE",
                  borderRadius: 1,
                  marginLeft: 2,
                  fontSize: 14,
                  inlineSize: "max-content"
                }}
                onClick={handleOpenCreateWorkflow}
                startIcon={<Add />}
              >
                <label>Tạo mới workflow</label>
              </Button>
            </Box>
          </Box>
          <Box className="workflow-tabs" sx={{ flexGrow: 1 }}>
            <ul className="tab-list">
              {tabItemsData.map((tabItem, i) => (
                <li
                  key={tabItem.code}
                  onClick={() => {
                    navigateSearch(ROUTE_PATH.WORKFLOWS_MANAGEMENT, {
                      tab:
                        i === 0
                          ? TabCode.AVAILABILITY
                          : i === 1
                            ? TabCode.CLOSED
                            : TabCode.MINE
                    });
                  }}
                  className={i === tabIndex ? "active" : ""}
                >
                  {tabItem.title}
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      </div>

      {tabIndex === 0 && (
        <WorkflowBoard
          key={`workflows-availability-${key}`}
          tabCode={TabCode.AVAILABILITY}
          isStatus={false}
        />
      )}

      {tabIndex === 1 && (
        <WorkflowBoard
          key={`workflows-closed-${key}`}
          tabCode={TabCode.CLOSED}
          isStatus
        />
      )}

      {tabIndex === 2 && (
        <WorkflowBoard
          key={`workflows-mine-${key}`}
          tabCode={TabCode.MINE}
          isMyBoard
          isStatus={false}
        />
      )}

      <CreateWorkflowDialog
        open={openWorkflowForm}
        handleClose={handleCloseCreateWorkflow}
        handleSuccess={handleCreateWorkflowSuccess}
      />
    </WorkflowManagementWrapper>
  );
};

export default WorkflowManagement;
