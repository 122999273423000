import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/auth/auth.duck";
import {
  ActionPopoverWrapper,
  NavbarWrapper,
  NotificationWrapper
} from "./styles";
import { Avatar, ImageCustom } from "..";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import LogoImage from "../../assets/images/login/login_logo_img_line_green.png";
import NotificationIcon from "../../assets/images/common/icon_notification_active_grey.svg";
import ProfileIcon from "../../assets/images/common/icon_user_line_grey.svg";
import SettingIcon from "../../assets/images/common/icon_setting_line_grey.svg";
import SupportIcon from "../../assets/images/common/icon_question_line_grey.svg";
import LogoutIcon from "../../assets/images/common/icon_logout_line_red.svg";
import { useEffect, useState } from "react";
import { toggleConfirmModal } from "../ConfirmModal/ConfirmModal";
import { ROUTE_PATH } from "../../common/constants/app.constant";
import { authTokens } from "../../services/services";
import { IUser } from "../../models/user";
import { useApi } from "../../hooks/useApi.hook";
import {
  IGroupNotification,
  INotification,
  INotificationCard
} from "../../models/notification";
import NotificationService from "../../services/api/notification.service";
import { TIME_FORMAT } from "../../config/constants";
import moment from "moment";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import useNavigateSearch from "../NavigateSearch";
import { toggleMessage } from "../Toast/Toast";
import { EFireBaseType } from "../../models/common/models.enum";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import variableStyles from "../../theme/variable-styles";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { theme } from "../../theme/theme";

type Props = {
  onMobileNavOpen: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const DashboardNavbar = ({ onMobileNavOpen }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [userInfo, setUserInfo] = useState<IUser | null>(null);
  const [action, setAction] = useState<"ACCOUNT" | "NOTIFICATION" | null>(null);
  const [notifications, setNotifications] = useState<IGroupNotification[]>([]);
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [totalNotification, setTotalNotification] = useState<number | null>(
    null
  );
  const [isUpdate, setIsUpdate] = useState(false);
  const [openRules, setOpenRules] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const getNotifications = useApi<INotificationCard>({
    isFetch: true,
    isPaging: true
  });
  const updateNotification = useApi<INotificationCard>({});
  const readAll = useApi<INotificationCard>({});

  const counter = useSelector((state: any) => state.users.notification);

  useEffect(() => {
    setTotalNotification((totalNotification ?? 0) + 1);
    setIsUpdate(true);
  }, [counter]);

  useEffect(() => {
    if (getNotifications.data) {
      let list = notifications.concat(
        getNotifications.data.groupNotification ?? []
      );
      const result = Array.from(new Set(list.map(list => list.created))).map(
        date => {
          return {
            created: date,
            notifications: list
              .filter(notification => notification.created === date)
              .reduce((total, value) => {
                return total.concat(value.notifications as []);
              }, [])
          };
        }
      );
      setNotifications(result);
      setTotalNotification(getNotifications.data?.totalNotSeen);
    }
  }, [getNotifications.data]);

  function fetchData() {
    getNotifications.request(
      NotificationService.getPage({
        CurrentPage: currentPage ?? 1,
        PageSize: 6
      })
    );
  }

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    async function getUserInfo() {
      const user = await authTokens.getUser();
      setUserInfo(user);
    }
    getUserInfo();
  }, []);

  const onLogout = (event: any) => {
    event.preventDefault();
    const asyncFuncLogout: () => Promise<void> = async () => {
      await Promise.resolve(dispatch(logoutUser(navigate)));
    };

    toggleConfirmModal({
      title: `Bạn có muốn đăng xuất`,
      content: `Bạn có chắc chắc muốn đăng xuất khỏi tài khoản này?`,
      onSubmit: () => asyncFuncLogout(),
      submitText: "Đăng xuất",
      type: "warning-red"
    });
  };

  const menuDataProfile: { icon: string; text: string; link: string }[] = [
    { icon: ProfileIcon, text: "Tài khoản", link: ROUTE_PATH.ACCOUNT },
    { icon: SettingIcon, text: "Cài đặt", link: "/" },
    { icon: SupportIcon, text: "Hỗ trợ", link: "/" }
  ];

  const UserPopup = () => {
    return (
      <>
        <div className="profile-infor">
          <Avatar
            src={userInfo?.avatar}
            alt="Avatar-Image"
            width={"32px"}
            height={"32px"}
          />
          <div className="infor-detail">
            <p className="detail-name">{userInfo?.fullName}</p>
            <p className="detail-email">{userInfo?.email}</p>
          </div>
        </div>
        <Divider />
        <ul className="profile-actions">
          {menuDataProfile.map((action, i) => (
            <li key={action.text}>
              <Link to={action.link}>
                <ImageCustom
                  src={action.icon}
                  alt="HEADER_ACTION"
                  width={"18px"}
                  height={"18px"}
                />
                <p>{action.text}</p>
              </Link>
            </li>
          ))}
        </ul>
        <Divider />
        <Button
          size="large"
          variant="text"
          className="profile-logout"
          onClick={onLogout}
        >
          <ImageCustom
            src={LogoutIcon}
            alt="HEADER_LOGOUT"
            width={"20px"}
            height={"20px"}
          />
          <p>Đăng xuất</p>
        </Button>
      </>
    );
  };

  const NotificationPopup = () => {
    useEffect(() => {
      if (isUpdate && notifications.length > 0) {
        setNotifications([]);
        fetchData();
        setIsUpdate(false);
      }
    }, []);

    const navigateSearch = useNavigateSearch();

    function checkPath(type: number, id?: string) {
      switch (type) {
        case EFireBaseType.AssignTask:
        case EFireBaseType.UpdateTask:
          if (id) {
            navigateSearch(ROUTE_PATH.TASK, {
              id: id
            });
          } else {
          }
          break;

        case EFireBaseType.RegisterTimeOff:
        case EFireBaseType.AprroveTimeOff:
          navigateSearch(ROUTE_PATH.TIMEOFF, {});
          break;

        case EFireBaseType.RegisterMeetingRoomSchedule:
        case EFireBaseType.AwaitCancelMeetingRoomSchedule:
        case EFireBaseType.AprroveMeetingRoomSchedule:
        case EFireBaseType.CancelMeetingRoomSchedule:
          navigateSearch(ROUTE_PATH.BOOKING, {});
          break;

        case EFireBaseType.ReleasedDocument:
          navigateSearch(ROUTE_PATH.DOCS, {});
          break;

        default:
          break;
      }
    }

    function handleCick(notification: INotification) {
      if (notification.viewed) {
        checkPath(
          notification.type,
          JSON.parse(notification.data).TaskId as string
        );
        setAnchorEl(null);
      } else {
        updateNotification.request(
          NotificationService.updateViewed({
            id: notification.id,
            Viewed: true
          }).then(response => {
            if (response.success) {
              if (totalNotification) {
                setTotalNotification(totalNotification - 1);
                setIsUpdate(true);
              }
              // navigate(
              //   `${ROUTE_PATH.TASK}?id=${JSON.parse(notification.data).TaskId}`
              // );
              checkPath(
                notification.type,
                JSON.parse(notification.data).TaskId as string
              );
              setAnchorEl(null);
            } else {
              toggleMessage({
                type: "error",
                message: response?.error ?? "Đã xảy ra lỗi"
              });
            }
          })
        );
      }
    }
    return (
      <NotificationWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography className="title" variant="subtitle2">
            Thông báo ({getNotifications.data?.totalNotSeen})
          </Typography>
          <Tooltip title={"Đọc tất cả thông báo"}>
            <Button
              onClick={() => {
                toggleConfirmModal({
                  title: `Bạn muốn đọc hết tất cả thông báo?`,
                  content: `Bạn chắc chắn muốn đọc hết các thông báo này?`,
                  onSubmit: async () =>
                    readAll.request(
                      NotificationService.readAll().then(res => {
                        if (res.success) {
                          toggleMessage({
                            type: "success",
                            message: "Cập nhật thành công"
                          });
                        } else {
                          toggleMessage({
                            type: "error",
                            message: res?.error ?? "Đã xảy ra lỗi"
                          });
                        }
                      })
                    ),
                  submitText: "Xác nhận",
                  type: "confirm"
                });
              }}
            >
              <DoneAllRoundedIcon className="check-svg" />
            </Button>
          </Tooltip>
        </div>
        <Divider />
        {notifications &&
          notifications.map((item, idx) => (
            <Box key={idx} marginBottom="4px">
              {
                <Box
                  display="flex"
                  marginBottom="8px"
                  justifyContent="space-between"
                  marginTop="4px"
                >
                  <Typography className="date-time" variant="caption">
                    {item.created}
                  </Typography>
                  <Typography
                    className="date-time"
                    variant="caption"
                    fontSize={10}
                    color="GrayText"
                  >
                    {moment(moment(item?.created, "DD/MM/YYYY")).fromNow()}
                  </Typography>
                </Box>
              }
              {item.notifications.map(notification => (
                <Box
                  key={notification.id}
                  display="flex"
                  padding="8px"
                  onClick={() => handleCick(notification)}
                  sx={{
                    backgroundColor: notification.viewed
                      ? "transparent"
                      : variableStyles.Info50
                  }}
                >
                  <CircleRoundedIcon
                    sx={{
                      fontSize: 8,
                      marginTop: "12px",
                      marginRight: "8px",
                      color: notification.viewed
                        ? "transparent"
                        : variableStyles.blueSecondary500
                    }}
                  />

                  <Badge
                    invisible={true}
                    color="primary"
                    overlap="circular"
                    variant="dot"
                  >
                    <Avatar src={notification?.imageUrl} />
                  </Badge>
                  <Stack
                    direction="column"
                    spacing={0.2}
                    sx={{
                      justifyContent: "center",
                      alignItems: "flex-start",
                      marginLeft: "12px"
                    }}
                  >
                    {/* <Typography variant="subtitle2">{notification.title}</Typography> */}
                    <div
                      style={{ fontSize: "12px" }}
                      dangerouslySetInnerHTML={{
                        __html: notification.title
                      }}
                    />
                    <Box display="flex" justifyItems="space-between">
                      <Typography
                        variant="caption"
                        fontSize={12}
                        color="GrayText"
                      >
                        {moment(notification.created).format(TIME_FORMAT)}
                      </Typography>
                      <Box />
                      {/* <CircleRoundedIcon
                        sx={{
                          fontSize: 6,
                          color: "GrayText",
                          margin: "6px 12px"
                        }}
                      /> */}
                      {/* <Typography variant="caption">Chưa đọc</Typography>  */}
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Box>
          ))}
        {getNotifications.pagging?.totalCount! > 6 &&
          currentPage !== getNotifications.pagging?.totalPages && (
            <Button
              size="small"
              variant="text"
              color="info"
              onClick={() => {
                setCurrentPage((currentPage ?? 1) + 1);
              }}
              style={{ textAlign: "center", width: "100%" }}
              endIcon={
                getNotifications.loading ? undefined : (
                  <KeyboardArrowDownRoundedIcon />
                )
              }
            >
              {getNotifications.loading ? (
                <CircularProgress />
              ) : (
                <Typography variant="subtitle2" fontSize={12}>
                  Hiển thị thông báo cũ hơn
                </Typography>
              )}
            </Button>
          )}
      </NotificationWrapper>
    );
  };

  return (
    <>
      <ActionPopoverWrapper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {action === "ACCOUNT" ? <UserPopup /> : <NotificationPopup />}
      </ActionPopoverWrapper>
      <NavbarWrapper>
        <div className="header-navigate">
          <Link to={ROUTE_PATH.WORKFLOWS_MANAGEMENT}>
            <ImageCustom
              src={LogoImage}
              alt="HEADER_LOGO"
              width={"auto"}
              height={"20px"}
            />
          </Link>
        </div>
        <div className="header-action">
          <div className="actice-group">
            <Button
              size="large"
              variant="text"
              className="action-button"
              onClick={event => {
                setAction("NOTIFICATION");
                setAnchorEl(event.currentTarget);
              }}
            >
              <Badge
                color="error"
                badgeContent={totalNotification}
                max={99}
                sx={{
                  "& .MuiBadge-standard": {
                    fontSize: matches ? "10px" : "12px"
                  }
                }}
              >
                <ImageCustom
                  src={NotificationIcon}
                  alt="Notification-Icon"
                  width={"24px"}
                  height={"24px"}
                />
              </Badge>
            </Button>
          </div>
          <Button
            size="large"
            variant="text"
            className="action-profile"
            onClick={event => {
              setAction("ACCOUNT");
              setAnchorEl(event.currentTarget);
            }}
          >
            <Avatar
              src={userInfo?.avatar}
              alt="Avatar-Image"
              width={"32px"}
              height={"32px"}
            />
            {!matches && (
              <Typography color="white" marginLeft="12px" fontSize={13}>
                Xin chào, {userInfo?.fullName}
              </Typography>
            )}
          </Button>
        </div>
      </NavbarWrapper>
    </>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
