import { Box } from "@mui/material";
import { Wrapper } from "./styles";
import { IStageHistory } from "../../../../../models/Task";
import {
  EStageType,
  ETaskStatus
} from "../../../../../models/common/models.enum";

interface Props {
  stages: IStageHistory[];
  currentStage: string;
}

const StageProgress = ({ currentStage, stages }: Props) => {
  // Find the index of the current stage
  const currentStageIndex = stages.findIndex(e => e.name === currentStage);
  console.log(stages);
  console.log(currentStage);
  console.log(currentStageIndex);
  return (
    <Wrapper>
      <div className="container">
        <div className="breadcrumb">
          {stages
            .filter(e => e.type === EStageType.Step)
            .map((e, i) => (
              <Box
                key={"progress" + e.id}
                className={
                  "breadcrumb-box" +
                  // `${i < currentStageIndex || currentStageIndex === stages.length - 1 ? " completed" : currentStageIndex === i ? " active" : ""}`
                  `${stages[i].histories && currentStageIndex > i ? " completed" : currentStageIndex === i ? " active" : ""}`
                }
              >
                <span className="breadcrumb__inner">
                  {i > currentStageIndex ? "" : e.name}
                </span>
              </Box>
            ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default StageProgress;
