import styled from "@emotion/styled";

export const Wrapper = styled("div")(() => ({
  borderRadius: "6px",
  marginTop: "10px",
  padding: 8,
  backgroundColor: "#f6f6f6",
  "& .MuiAccordion-root": {
    backgroundColor: "#f6f6f6",
    boxShadow: "none",
    padding: 0,
  },
  "& .MuiAccordionSummary-root": {
    minHeight: "32px !important",

  },
  "& .MuiAccordionSummary-content": {
    margin: "0 !important"
  }

}));
