import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/styles";

export const Alert = styled(MuiAlert)(() => ({
  padding: "8px 24px",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .MuiAlert-icon": {
    opacity: 1,
    fontSize: "24px",
    "& .MuiSvgIcon-root": {
      fontSize: "24px"
    }
  },

  "& .MuiAlert-message": {
    maxWidth: "300px",
    padding: "0px",

    "& .MuiTypography-root": {
      margin: "0px !important",
      "& .body-description": {
        color: "#fff",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "1.25"
      }
    }
  },

  "& .MuiAlert-action": {
    marginRight: 0,
    marginLeft: 12,
    padding: "0px",

    "& .MuiBox-root": {
      cursor: "pointer",
      width: "24px",
      height: "24px"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "24px"
    }
  },

  "&.MuiAlert-standard": {
    backgroundColor: "#fff",

    "& .MuiAlert-message": {
      maxWidth: "300px",
      "& .title": {
        color: "#1E2831"
      },
      "& .body-description": {
        color: "#4E555C"
      }
    },
    "& .MuiAlert-action": {
      color: "#9A9DB2"
    }
  },

  "&.MuiAlert-standardSuccess": {
    "& .MuiAlert-icon": {
      color: "#3DBC4A"
    }
  },
  "&.MuiAlert-standardWarning": {
    "& .MuiAlert-icon": {
      color: "#FFAC14"
    }
  },
  "&.MuiAlert-standardInfo": {
    "& .MuiAlert-icon": {
      color: "#00AFFF"
    }
  },
  "&.MuiAlert-standardError": {
    "& .MuiAlert-icon": {
      color: "#FF5151"
    }
  },

  "&.MuiAlert-filledError": {
    backgroundColor: "#FF5151"
  },
  "&.MuiAlert-filledSuccess": {
    backgroundColor: "#3DBC4A"
  },
  "&.MuiAlert-filledWarning": {
    backgroundColor: "#FFAC14"
  },
  "&.MuiAlert-filledInfo": {
    backgroundColor: "#00AFFF"
  }
}));

export default Alert;
