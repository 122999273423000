import { Box, Button, Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, useEffect, useRef, useState } from "react";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import useAxios from "../../../../components/UseAxios/useAxios";
import variableStyles from "../../../../theme/variable-styles";
import TimeOffTable from "../DataTable/TimeOffDataTable";
import { IBasePaging } from "../../../../models/common/models.type";
import { toggleMessage } from "../../../../components/Toast/Toast";
import { ITimeOff } from "../../../../models/timeoff";
import TimeOffService from "../../../../services/api/timeoff.service";
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { IDownloadFile } from "../../../../models/Comment";
import { b64toBlob } from "../../../../common/handles/common.handles";

interface IProps {
  open: boolean;
  canExportExcel: boolean;
  handleClose: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const defaultPaging = {
  currentPage: 1,
  pageSize: 10,
  totalCount: 0,
  totalPages: 0
};

const ExportTimeOffModal = ({ open, canExportExcel, handleClose }: IProps) => {
  const [paging, setPaging] = useState<IBasePaging>(defaultPaging);
  const [currentPage, setCurrentPage] = useState(1);
  const [timeOffProposes, setTimeOffProposes] = useState<ITimeOff[]>([]);
  const getTimeOffsAxios = useAxios<ITimeOff[]>({ loading: "OnRequest" });
  const exportExcelAxios = useAxios<IDownloadFile>({ loading: "OnRequest" });
  const [timeFilter, settimeFilter] = useState(dayjs());

  const tableRef = useRef<HTMLDivElement>(null);
  const getTimeOffs = () => {
    getTimeOffsAxios.request(
      TimeOffService.viewUserRegisterTimeOff({
        CurrentPage: currentPage,
        PageSize: paging.pageSize,
        Month: timeFilter.month() + 1,
        Year: timeFilter.year()
      })
    );
  };

  useEffect(() => {
    if (open === false) {
      getTimeOffsAxios.reset();
      setTimeOffProposes([]);
      setPaging(defaultPaging);
      setCurrentPage(1);
      settimeFilter(dayjs());
    }
  }, [open]);

  useEffect(() => {
    if (open) getTimeOffs();
  }, [currentPage, open, timeFilter]);

  useEffect(() => {
    if (getTimeOffsAxios.isSuccess && getTimeOffsAxios.data) {
      setTimeOffProposes(getTimeOffsAxios.data);
      setPaging(getTimeOffsAxios.paging!);
    }
    if (getTimeOffsAxios.error) {
      toggleMessage({
        type: "error",
        message: getTimeOffsAxios.error?.message ?? ""
      });
    }
  }, [getTimeOffsAxios.isSuccess, getTimeOffsAxios.error]);

  useEffect(() => {
    if (exportExcelAxios.isSuccess && exportExcelAxios.data) {
      const blob = b64toBlob(
        exportExcelAxios.data.fileContent,
        exportExcelAxios.data.fileName
      );
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", exportExcelAxios.data.fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
      window.URL.revokeObjectURL(url);
    }

    if (exportExcelAxios.error) {
      toggleMessage({
        type: "error",
        message: exportExcelAxios.error?.message ?? ""
      });
    }
  }, [exportExcelAxios.isSuccess, exportExcelAxios.error]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleExport = () => {
    exportExcelAxios.request(
      TimeOffService.exportExcelTimoff({
        Month: timeFilter.month() + 1,
        Year: timeFilter.year()
      })
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-describedby="timeoff-dialog-export"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#267CDE",
          padding: "8px 24px"
        }}
      >
        <Typography
          fontSize={16}
          fontWeight={500}
          color={variableStyles.NaturalColor300}
        >
          {`Xuất Excel`}
        </Typography>
        <CloseRoundedIcon
          onClick={handleClose}
          sx={{ color: "white", height: 24, width: 24 }}
        />
      </Box>
      <LoadingOverlayWrapper
        active={getTimeOffsAxios.isLoading && timeOffProposes.length === 0}
        spinner
        styles={{
          overlay: {
            position: "absolute",
            height: "100%",
            width: "100%",
            top: "0px",
            left: "0px",
            display: "-webkit-box",
            textAlign: "center",
            fontSize: "1.2em",
            color: "#FFF",
            backgroundColor: "rgba(199, 199, 199, 0.7)",
            zIndex: 800,
            webkitTransition: "opacity 500ms ease-in",
            transition: " opacity 500ms ease-in",
            opacity: 1
          }
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          p={1.5}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="vi">
            <MobileDatePicker
              slotProps={{
                textField: {
                  placeholder: "Chọn thời gian xuất excel"
                },
                toolbar: {
                  className: "dialog-toolbar",
                  sx: {
                    span: {
                      fontSize: 12
                    },
                    h4: {
                      fontSize: 20
                    }
                  }
                },
                calendarHeader: {
                  sx: {
                    "& .MuiPickersCalendarHeader-labelContainer": {
                      fontSize: 16
                    },
                    "& .MuiSvgIcon-root": {
                      width: 24,
                      height: 24
                    },
                    button: { fontSize: 18 }
                  }
                },
                mobilePaper: {
                  sx: {
                    "& .MuiDayCalendar-weekDayLabel": {
                      fontSize: 12
                    },
                    "& .MuiPickersDay-root": {
                      fontSize: 12
                    },
                    "& .MuiPickersMonth-monthButton": {
                      fontSize: 14
                    },
                    "& .MuiPickersYear-yearButton": {
                      fontSize: 14
                    },
                    "& .MuiButtonBase-root": {
                      fontSize: 14
                    }
                  }
                }
              }}
              value={timeFilter}
              onChange={value => settimeFilter(value ?? dayjs())}
              maxDate={dayjs()}
              openTo="month"
              views={["year", "month"]}
              sx={{ minWidth: 220 }}
            />
          </LocalizationProvider>
          {timeOffProposes.length > 0 && canExportExcel && (
            <Button
              onClick={handleExport}
              sx={{
                color: "white",
                borderRadius: 1,
                height: 40,
                fontSize: 14
              }}
              variant="contained"
            >
              Xuất excel
            </Button>
          )}
        </Box>
        <Box
          ref={tableRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minHeight: `${tableRef?.current?.clientHeight}px`
          }}
        >
          {timeOffProposes.length > 0 && (
            <TimeOffTable
              tab={"export"}
              updateType=""
              canUpdateStatus={false}
              canDeleteTimeoff={false}
              proposes={timeOffProposes}
              paging={paging}
              onPageChange={handlePageChange}
            />
          )}
          {timeOffProposes.length === 0 && (
            <Box
              height={"150px"}
              width={"100%"}
              textAlign="center"
              alignContent="center"
            >
              {!getTimeOffsAxios.isLoading && "Không có dữ liệu"}
            </Box>
          )}
        </Box>
      </LoadingOverlayWrapper>
    </Dialog>
  );
};

export default ExportTimeOffModal;
